import React from "react";
import { useState,useEffect } from "react";
import { useAlert } from "react-alert";
import * as Global from '../../constants/Global'
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { isStringValue } from "../../utility-files/data-util/DataHandler";
import { alertTypes, errorMessages } from "../../utility-files/helper-function/HelperFunction";
import CustomLoader from '../custom-modules/CustomLoader';
import * as DataHandler from "../../utility-files/data-util/DataHandler";
import * as CustomInput from "../../utility-files/custom-input/CustomInput";



function AssignEmail({EmailWallet, closeEmail,walletId ,onSuccess}){
    const[walletEmail,setWalletEmail]=useState(null)
    const alert = useAlert();
    const[pending,setPending]=useState(false)
    const [isSubmitted, setSubmitted] = useState(false)

    const handleSubmit = () => {
        addWalletEmail();
    };
    useEffect(() => {
        if (walletEmail) {
            setSubmitted(false)
            return;
        }
    }, [walletEmail])

    const addWalletEmail = async () => {
        setPending(true);
        let variables = {
            id: DataHandler.isIntegerValue(walletId),
            email: isStringValue(walletEmail?.email)
        };
        if (!walletEmail?.isEmailValid) {
            setPending(false)
            setSubmitted(true)
            return;
        }
        let request = getRequestForApi(Global.ADD_EMAIL_WALLET, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Email assign successfully to wallet.", { type: alertTypes.SUCCESS });
                setWalletEmail(null);  
                setPending(false);
                onSuccess()
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    return(
        <>
       
          {<CustomLoader pending={pending}/>}
         <div className={`modal ${EmailWallet ? "modal-show" : "fade"} bd-example-modal-sm`} id="AddName67" tabIndex="-1" aria-labelledby="AddName67" aria-hidden="false"data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Wallet Email</h5>
                            {/* <button type="button" className="small btn-close wallet-name-modal" data-bs-dismiss="modal"
                                aria-label="Close"  onClick={() =>{closeEmail();setWalletEmail(null)}}></button> */}
                        </div>
                        <div className="modal-body">
                            <CustomInput.Email
                                setData={setWalletEmail}
                                keyName={'email'}
                                className={'form-control'}
                                id="email"
                                placeholder={"Enter your email"}
                                value={DataHandler.isStringValue(walletEmail?.['email'])}
                                isrequired={true}
                            />
                        </div>
                        <p className='warninig02'>{isSubmitted && walletEmail?.email && !walletEmail?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p>
                        <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={() => { setWalletEmail(null);closeEmail() }}>Cancel</button>
                            <button type="button" className={`btn btn-primary ${!walletEmail?.email ? 'disable-button' : ''}`} data-bs-dismiss="modal" onClick={() => {handleSubmit();closeEmail()}}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}
export default  AssignEmail