import React from 'react'
import { sourceName as source } from '../../utility-files/helper-function/HelperFunction'

function FeedbackExport({ isShow, setIsShow, sourceName, dataList, callApi }) {
    return (
        <>
            <div className={`modal ${isShow ? "modal-show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border d-flex justify-content-between align-items-center">
                            <div>
                                <p className='mb-0'>Are you sure you want to export {sourceName === source.reports ? 'report' :'feedback'}'s list?</p>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-end mt-2">
                            <button type="button" className="btn btn-secondary" onClick={() => callApi(dataList, sourceName)}>Yes</button>
                            <button type="button" className="btn btn-primary" onClick={() => setIsShow(!isShow)}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbackExport