import React, { useEffect,useState } from 'react'
import * as CustomInput from '../../utility-files/custom-input/CustomInput'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import { errorMessages } from '../../utility-files/helper-function/HelperFunction';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import CustomLoader from '../custom-modules/CustomLoader';

function UpdateWalletName({ userId, closeUpdateWallet, walletNameId, onSuccess, dataPopUP }) {
    const alert = useAlert();
    const [updateData, setUpdateData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (dataPopUP) {
            setUpdateData(dataPopUP);
        } else {
            setUpdateData(null);
        }
    }, [userId]);

    const walletNameEdit = async (e) => {
        e.preventDefault();
        let variables = {
            id: DataHandler.isIntegerValue(walletNameId),
            name: DataHandler.isStringValue(updateData?.name)
        };
        setPending(true);

        let request = getRequestForApi(Global.UPDATE_WALLET_NAME, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let apiRes = response?.data?.response?.data;
                alert.show("Wallet name updated successfully.", { type: alertTypes.SUCCESS });
                setUpdateData(null);
                setSubmitted(false);
                onSuccess()
                closeUpdateWallet()
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${userId ? "modal-show" : "fade"}`} id="deleteToken452" tabIndex="-1" role="dialog" aria-labelledby="deleteToken452" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border">
                            <div className="modal-head">
                                <h5 className="modal-title" id="exampleModalLabel">Edit Wallet Name</h5>
                            </div>
                        </div>
                        <div className="form-group px-4">
                            {dataPopUP &&
                                <CustomInput.Text
                                    setData={setUpdateData}
                                    keyName={"name"}
                                    value={DataHandler.isStringValue(updateData?.['name'])}
                                    className="form-control default-font"
                                    id="name"
                                    placeholder={"Enter wallet name"}
                                />
                            }
                            {submitted && <p className='warninig'>{DataHandler.isStringValue(updateData?.name) === '' ? errorMessages.REQUIRED_FIELD : !updateData?.name ? errorMessages.updateData : ''}</p>}
                        </div>
                        <div className="modal-footer hide-border mt-3">
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal" onClick={() => { setUpdateData(null); closeUpdateWallet() }}>Cancel</button>
                            <button type="button" className={`btn btn-primary ${!updateData?.name ? 'disable-button' : ''}`} data-bs-dismiss="modal" onClick={(e) => walletNameEdit(e)} >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UpdateWalletName

