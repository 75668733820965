import React, { useState } from 'react'
import * as Global from '../../constants/Global'
import { getDateFormat, getTimeFormat, maxDate } from '../../utility-files/date-util/DateHandling';
import CommonTable from '../../custom-ui/CommonTable';
import CustomLoader from '../custom-modules/CustomLoader';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import { REPORT_FOR, alertTypes, downloadXMLfile, exportUserFileName, exportVaultName, jsonToXLSXFile, sourceName } from '../../utility-files/helper-function/HelperFunction';
import FeedbackExport from '../common-module/FeedbackExport';
import { useAlert } from 'react-alert';
import ApiHelper from '../../config/ApiHelper';

function Reports() {
    const alert = useAlert();
    const [reportsList, setReportsList] = useState(null);
    const [pending, setPending] = useState(false);
    const [filterDate, setFilterDate] = useState(null);
    const [showCol, setShowCol] = useState(false);
    const [feedbackExport, setFeedbackExport] = useState(false);

    const listReports = async () => {
        setPending(true);
        let variables = {};
        variables.fromDate = filterDate.fromDate;
        variables.toDate = filterDate.toDate;
        variables.reportFor = filterDate.reportFor;
        let request = getRequestForApi(Global.READ_USER_REPORT, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                setReportsList(responseData);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const reportsColumns = [
        {
            name: <th>{`${showCol ? 'Old ' : ''} Name`}</th>,
            selector: row => row?.displayName,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.displayName)}
            </>)
        },
        {
            name: <th>Wallet Name</th>,
            selector: row => row?.walletName,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.walletName)}
            </>)
        },
        {
            name: <th>Email</th>,
            selector: row => row?.email,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.email)}
            </>)
        },
        {
            name: <th>Organization</th>,
            selector: row => row?.organization,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.organization)}
            </>)
        },
        {
            name: <th>Contact Number</th>,
            selector: row => row?.contactNumber,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.contactNumber)}
            </>)
        },
        {
            name: <th>Registration Date</th>,
            selector: row => getDateFormat(row?.registrationDate),
            sortable: true,
            cell: row => (<>
                {getDateFormat(row?.registrationDate)} {getTimeFormat(row?.registrationDate)}
            </>)
        }
    ];

    if (showCol) {
        reportsColumns.splice(1, 0, {
            name: <th>Name</th>,
            selector: row => row?.currentData,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.currentData)}
            </>)
        })
        reportsColumns.push({
            name: <th>Update Date</th>,
            selector: row => getDateFormat(row?.updateDate),
            sortable: true,
            cell: row => (<>
                {getDateFormat(row?.updateDate)} {getTimeFormat(row?.updateDate)}
            </>)
        });
    };

    const callBackApi = async () => {
        setPending(true);
        let requestUrl, fileName;
        setPending(true);
        requestUrl = Global.EXPORT_USERS_REPORT + `/${filterDate.fromDate}` + `/${filterDate.toDate}` + `/${filterDate.reportFor}`;
        fileName = exportUserFileName(sourceName.reports, filterDate.reportFor);
        await ApiHelper.get(requestUrl, { responseType: 'blob' }).then((response) => {
            if (response?.status === 200) {
                downloadXMLfile(response?.data, fileName);
                setPending(false);
                setFeedbackExport(false);
                setReportsList(null);
                alert.show(`Report's downloaded successfully`, { type: alertTypes.SUCCESS });
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
        });
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        if (id === 'fromDate') {
            setFilterDate({ ...filterDate, [id]: value });
        } else {
            if (!filterDate?.fromDate) {
                alert.show('Please select from date', { type: alertTypes.INFO })
                return;
            };
            setFilterDate({ ...filterDate, [id]: value });
        };
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <section className="table-section-listing pt-0"></section>
            <section className="table-section-listing  pt-0">
                <div className="row table-row-padding">
                    {/* <div className="col-lg-4 d-flex justify-content-initial gap-4">
                    </div> */}
                    <div className="col-lg-12">
                        <div className="input-group rounded d-flex justify-content-end gap-4">
                            {/* <div className="input-group mb-3 w-25">

                            </div> */}
                            <div className="d-flex justify-content-between align-items-center gap-3">
                                <div className='d-flex gap-2'>
                                    <label htmlFor="fromDate">From date:</label>
                                    <input type="date" id="fromDate" name="trip-fromDate" value={DataHandler.isStringValue(filterDate?.fromDate)} max={DataHandler.isStringValue(filterDate?.toDate) || DataHandler.isStringValue(maxDate())} onChange={(event) => handleDateChange(event)} />
                                </div>
                                <div className='d-flex gap-2'>
                                    <label htmlFor="to">To date:</label>
                                    <input type="date" id="toDate" name="trip-fromDate" value={DataHandler.isStringValue(filterDate?.toDate)} min={DataHandler.isStringValue(filterDate?.fromDate)} max={DataHandler.isStringValue(maxDate())} onChange={(event) => handleDateChange(event)} />
                                </div>
                                <div className='d-flex gap-2'>
                                    <label htmlFor="Report's for">Report's for:</label>
                                    <select value={DataHandler.isStringValue(filterDate?.reportFor)} onChange={(event) => setFilterDate({ ...filterDate, reportFor: event.target.value })}>
                                        {DataHandler.isArrayValue(REPORT_FOR)?.map((item) => {
                                            return <option key={item?.id} value={item?.id}>{item?.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={DataHandler.isStringValue(filterDate?.fromDate) && DataHandler.isStringValue(filterDate?.reportFor) ? '' : 'disable-button'}>
                                <button type="button" className="btn btn-primary d-flex align-items-center" onClick={() => { setShowCol(DataHandler.isStringValue(filterDate?.reportFor) === REPORT_FOR[1].id); listReports() }}>
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.SEARCH_WHITE}
                                        alt=""
                                    />
                                    Search
                                </button>
                            </div>
                            <div className={DataHandler.isArrayValue(reportsList).length === 0 ? 'disable-button' : ''}>
                                <button type="button" className="btn btn-primary d-flex align-items-center" onClick={() => setFeedbackExport(!feedbackExport)}>
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.FEEDBACK_WHITE}
                                        alt=""
                                    />
                                    Export Report's
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"50vh"}
                        columns={reportsColumns}
                        data={reportsList}
                    />
                </div>
            </section>
            <FeedbackExport isShow={feedbackExport} setIsShow={setFeedbackExport} sourceName={sourceName.reports} callApi={callBackApi} dataList={reportsList} />
        </>
    )
}

export default Reports
