import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import * as StorageHandling from '../utility-files/storege-util/StorageHandling';
import { path } from '../utility-files/helper-function/HelperFunction';
import Header from '../common-pages/Header';
import SideNav from '../common-pages/SideNav';

export const PrivateRoute = ({ children }) => {
    const isWelcomePage = window.location.pathname === path.LOGIN;
    const token = StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) ? StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) : StorageHandling.getStorageData(!StorageHandling.storageType, StorageHandling.storageKey.TOKEN);
    return <>
        {isWelcomePage && !token && children}
        {!isWelcomePage && token ? <>
            <SideNav />
            {children}
        </> : <Navigate replace to={path.LOGIN} />}
    </>
}


export default PrivateRoute