import * as DataHandler from "../data-util/DataHandler";

const handleOnChangeData = (event, dataKey, field, handleOnChange, minValue, maxValue) => {
    let validationKey = 'is' + dataKey.charAt(0).toUpperCase() + dataKey.slice(1) + 'Valid';
    if (field === 'text') {
        if (minValue && event[0] < minValue) {
            return;
        }
        handleOnChange(state => ({ ...state, [dataKey]: DataHandler.handleTextValue(event), [validationKey]: DataHandler.isTextData(event) }));
    } else if (field === 'number') {
        if (minValue && event[0] < minValue) {
            return;
        }
        if (maxValue) {
            if (DataHandler.isIntegerValue(DataHandler.handleTextValue(event)) <= DataHandler.isIntegerValue(maxValue)) {
                handleOnChange(state => ({ ...state, [dataKey]: DataHandler.handleTextValue(event), [validationKey]: DataHandler.isNumberData(DataHandler.handleTextValue(event)) }));
            }
        } else {
            handleOnChange(state => ({ ...state, [dataKey]: DataHandler.handleTextValue(event), [validationKey]: DataHandler.isNumberData(DataHandler.handleTextValue(event)) }));
        }
    } else if (field === 'contactNumber') {
        if (event?.length <= 15) {
            handleOnChange(state => ({ ...state, [dataKey]: DataHandler.handleTextValue(event), [validationKey]: DataHandler.isNumberData(event) }));
        }
    } else if (field === 'email') {
        handleOnChange(state => ({ ...state, [dataKey]: event, [validationKey]: DataHandler.isEmailData(event) }));
    } else if (field === 'password') {
        handleOnChange(state => ({ ...state, [dataKey]: event, [validationKey]: DataHandler.isPasswordData(event) }));
    } else {
        handleOnChange(state => ({ ...state, [dataKey]: event }));
    };
};

const Text = ({ setData, keyName,minValue,  ...rest }) => {
    return <input type="text"  min={minValue ? minValue : "0"}  onChange={(event) => handleOnChangeData(event.target.value, keyName, "text", setData,minValue)} {...rest} />
};

const TextArea = ({ setData, keyName, ...rest }) => {
    return <textarea onChange={(event) => handleOnChangeData(event.target.value, keyName, "text", setData)} {...rest} />
}

const Password = ({ setData, keyName, showPassword, ...rest }) => {
    return <input type={showPassword ? "text" : "password"} onChange={(event) => handleOnChangeData(event.target.value, keyName, "password", setData)} {...rest} />
};

const Number = ({ setData, keyName, minValue, maxValue, ...rest }) => {
    let invalidChars = [
        ".",
        "-",
        "+",
        "e",
    ];
    return <input type="number" min={minValue ? minValue : "0"} onKeyDown={(e) => {
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    }} onChange={(event) => handleOnChangeData(event.target.value, keyName, "number", setData, minValue, maxValue)} {...rest} />
};

const ContactNumber = ({ setData, keyName, ...rest }) => {
    var invalidChars = [
        ".",
        "-",
        "+",
        "e",
    ];
    return <input type="number" min="0" onKeyDown={(e) => {
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    }} onChange={(event) => handleOnChangeData(event.target.value, keyName, "contactNumber", setData)} {...rest} />
};

const Date = ({ setData, keyName, ...rest }) => {
    return <input type="date" onChange={(event) => handleOnChangeData(event.target.value, keyName, "date", setData)} {...rest} />
};

const Email = ({ setData, keyName, ...rest }) => {
    return <input type="email" onChange={(event) => handleOnChangeData(event.target.value, keyName, "email", setData)} {...rest} />
};

const Select = ({ setData, selectList, keyName, ...rest }) => {
    return <select onChange={(event) => handleOnChangeData(event.target.value, keyName, "select", setData)} {...rest}>
        {DataHandler.isArrayValue(selectList)?.map((item) => {
            return <option key={item?.id} value={item?.id}>{item?.name}</option>
        })}
    </select>
}

export {
    Text,
    TextArea,
    Password,
    Number,
    ContactNumber,
    Date,
    Email,
    Select
}