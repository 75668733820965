import React from 'react'
import '../../assets/css/loader.css';
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';


function CustomLoader({ pending }) {
    return (
        <>
            {pending && <div className='loader-wrapper'>
                <img src={IMAGE_TYPES.LOAD_GIF} alt="loader" />
            </div>}
        </>
    )
}

export default CustomLoader