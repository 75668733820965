import React, { useState } from 'react'
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import * as DataHandler from "../../utility-files/data-util/DataHandler"

function SideNavToggle({ userData, onSuccess }) {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleLogOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };

    return (
        <>
            <div className="modal fade" id="SideNavToggle" tabIndex="-1" aria-labelledby="SideNavToggle" aria-hidden="false">
                <div className="modal-dialog modal-dialog-right modal-sm">
                    <div className="modal-content sidenav-modal">
                        <div className="modal-header header-content pb-0">
                            <div className="row">
                                <div className="col-3 header-content-img p-1">
                                    <img src={DataHandler.isStringValue(userData?.profileImageUrl) || IMAGE_TYPES.SKULL} height='25px' width='25px' alt="profile2" />
                                </div>
                                <div className="col-9 header-content-text ps-2">
                                    <h5 className="mb-0 me-2">{DataHandler.isStringValue(userData?.displayName)}</h5>
                                    <p>{DataHandler.isStringValue(userData?.type)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body sidenav-padding pt-0">
                            <div className="row pointer profile-Show">
                                <div className="col-3 header-content-img">
                                    <img src={IMAGE_TYPES.SETTING_LOGO} alt="settings" />
                                </div>
                                <div className="col-9 header-content-text p-0" id="Sidenav">
                                    <h4>Setting</h4>
                                </div>
                            </div>
                            <div className="row pointer" data-bs-dismiss="modal">
                                <div className="col-3 header-content-img">
                                    <img src={IMAGE_TYPES.LOGOUT_LOGO} alt="logout" onClick={() => handleLogOut()} />
                                </div>
                                <div className="col-9 header-content-text p-0" onClick={() => handleLogOut()}>
                                    <h4>Logout</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Profile userData={userData} showModal={showModal} setShowModal={setShowModal} onSuccess={onSuccess} />
        </>
    )
}

export default SideNavToggle
