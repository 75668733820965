import React, { useEffect, useState } from 'react'
import * as CustomInput from "../../utility-files/custom-input/CustomInput"
import { isStringValue } from '../../utility-files/data-util/DataHandler'
import * as Global from "../../constants/Global"
import * as DataHandler from "../../utility-files/data-util/DataHandler"
import { useAlert } from 'react-alert'
import { alertTypes } from '../../utility-files/helper-function/HelperFunction'
import $ from 'jquery';
import CustomLoader from '../custom-modules/CustomLoader'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest'


function ChangeName(props) {
    const { userData, onSucess } = props;
    const alert = useAlert();
    const [pending, setPending] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [close, setClose] = useState(false);


    const handleSubmit = () => {
        updateUserName();
    };

    useEffect(() => {
        if (userData) {
            setUserDetails(userData)
        }
    }, [userData,close])

    const updateUserName = async () => {
        setPending(true);
        let variables = {
            name: isStringValue(userDetails?.firstName)
        };
        let request = getRequestForApi(Global.GET_UPDATE_USERNAME, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Name updated successfully ", { type: alertTypes.SUCCESS });
                $('#ChangePassword').modal('hide');
                $('#Profile').modal('show');
                setUserDetails(null);  
                setPending(false);
                onSucess();

            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };
    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className="modal fade" id="ChangeName" tabIndex="-1" aria-labelledby="ChangeName" aria-hidden="false"data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Change {"modelName" === 'wallet' ? 'Wallet' : ''} Name</h5>
                            <button type="button" className="small btn-close close-name-modal" data-bs-dismiss="modal"
                                aria-label="Close"  onClick={() => setClose(!close)}></button>
                        </div>
                        <div className="modal-body">
                            <CustomInput.Text
                                setData={setUserDetails}
                                keyName={'firstName'}
                                className={'form-control'}
                                id="first-name"
                                placeholder={"Enter your name"}
                                value={DataHandler.isStringValue(userDetails?.['firstName'])}
                                isrequired={true}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary ${!userDetails?.firstName ? 'disable-button' : ''}`} data-bs-dismiss="modal" onClick={() => {handleSubmit()}}>Save</button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default ChangeName