import React from 'react'
import { Navigate } from 'react-router-dom';
import CommonLoginModule from '../pages/user-module/CommonLoginModule';
import * as StorageHandling from '../utility-files/storege-util/StorageHandling';
import { path } from '../utility-files/helper-function/HelperFunction';
import Header from '../common-pages/Header';

export const LoginSignupRoute = ({ children }) => {
    const token = StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) || StorageHandling.getStorageData(!StorageHandling.storageType, StorageHandling.storageKey.TOKEN);
    const moduleHandler = () => {
        if (token) {
            return <Navigate replace to={path.HOME} />
        } else {
            return children
        }
    };
    return <>
        <Header />
        <CommonLoginModule>
            {moduleHandler()}
        </CommonLoginModule>
    </>
}


export default LoginSignupRoute