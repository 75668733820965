import React, { useEffect, useState } from 'react'
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues'
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import * as Global from '../../constants/Global'
import { CallApiHandler } from '../../utility-files/custom-hooks/CallApiHandler';
import { sourceName } from '../../utility-files/helper-function/HelperFunction';

function CardSection({ source, refetch, setRefetch }) {
    const [data, setTokenDetails] = useState(null);
    const [pending, setPending] = useState(false);
    useEffect(() => {
        if (source === sourceName.businessLite) {
            businessLiteData();
        };
        if (source === sourceName.consumerLite) {
            consumerListData();
        }
        setRefetch(false);
    }, [source, refetch]);

    const businessLiteData = async () => {
        setPending(true);
        const response = await CallApiHandler(
            Global.BUSSINESSLITE_DASHBOARD,
            undefined
        );
        const tokenDetails = response?.data;
        if (tokenDetails) {
            setTokenDetails(tokenDetails);
        }
        setPending(false);
    };

    const consumerListData = async () => {
        setPending(true);
        const response = await CallApiHandler(Global.CONSUMERLITE_DASHBOARD, undefined);
        const tokenDetails = response?.data
        if (tokenDetails) {
            setTokenDetails(tokenDetails);
        };
        setPending(false);
    };
    return (
        <section className="card-section pb-0">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="card">
                            <img className="card-img-top" src={IMAGE_TYPES.TOTALUSER_IMAGE} alt="Card image cap" />
                            <div className="card-body py-0">
                                <p className="card-title my-0">Total Users</p>
                                <h4 className="card-text">{DataHandler.isIntegerValue(data?.activeUsers)} <span>/{DataHandler.isIntegerValue(data?.totalUsers)}</span></h4>
                                <div className="arrow-div-admin d-flex gap-1">
                                    {/* <img className="m-0" src={IMAGE_TYPES.GREENICON_IMAGE} alt="" />
                                        <p>2% increase from last month</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="card">
                            <img className="card-img-top" src={IMAGE_TYPES.TOKEN} alt="Card image cap" />
                            <div className="card-body py-0">
                                <p className="card-title my-0">Free Token Usage</p>
                                <h4 className="card-text">{DataHandler.isIntegerValue(data?.freeTokenUsage)} <span>/{DataHandler.isIntegerValue(data?.totalFreeTokens)}</span></h4>
                                <div className="arrow-div-admin d-flex gap-1">
                                    {/* <img className="m-0" src={IMAGE_TYPES.ICON_IMAGE} alt="" />
                                        <p>8% increase from last month</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="card">
                            <img className="card-img-top" src={IMAGE_TYPES.TOKEN2_IMAGE} alt="Card image cap" />
                            <div className="card-body py-0">
                                <p className="card-title my-0">Purchased Token Usage</p>
                                <h4 className="card-text"><strong>{DataHandler.isIntegerValue(data?.purchasedTokenUsage)}</strong> <span>/{DataHandler.isIntegerValue(data?.totalPurchasedTokens)}</span></h4>
                                <div className="arrow-div-admin d-flex gap-1">
                                    {/* <img className="m-0" src={IMAGE_TYPES.RED_IMAGE} alt="" /> */}
                                    {/* <p>10% decrease from last month</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="card">
                            <img className="card-img-top" src={IMAGE_TYPES.TOTALSALE_IMAGE} alt="Card image cap" />
                            <div className="card-body py-0">
                                <p className="card-title my-0">Total Sale Value</p>
                                <h4 className="card-text"><strong>${DataHandler.isIntegerValue(data?.totalSaleAmount)}</strong></h4>
                                <div className="arrow-div-admin d-flex gap-1">
                                    {/* <img className="m-0" src={IMAGE_TYPES.GREENICON_IMAGE} alt="" />
                                        <p>12% increase from last month</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default CardSection