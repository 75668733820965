import React, { useEffect, useState } from "react";
import { IMAGE_TYPES } from "../../utility-files/default-values/DefaultValues";
import { CallApiHandler } from "../../utility-files/custom-hooks/CallApiHandler";
import * as Global from "../../constants/Global";
import CustomLoader from "../custom-modules/CustomLoader";
import UserToken from "../custom-popup-modal/UserToken";
import Confirmation from "../common-module/Confirmation";
import CommonTable from "../../custom-ui/CommonTable";
import { alertTypes, roleTypes, sourceName } from "../../utility-files/helper-function/HelperFunction";
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import PlansModal from "../common-module/PlansModal";
import CardSection from "../common-module/CardSection";
import ExportUsers from "../common-module/ExportUsers";
import { getDateFormat, getTimeFormat } from "../../utility-files/date-util/DateHandling";
import AttachmentConfig from "../custom-popup-modal/AttachmentConfig"
import CustomConfirmation from "../custom-modules/CustomConfirmation";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { useAlert } from "react-alert";
import { encryptData } from "../../utility-files/data-encryption-util/DataEncryption";
import ViewTokens from "./ViewTokens";
import GrantAdmin from "../custom-popup-modal/GrantAdmin";
import TransactionHistory from "../common-module/TransactionHistory";
import ViewActivity from "../custom-popup-modal/ViewActivity";
import FreeUserToken from "../custom-popup-modal/FreeUserToken";

function BussinessLite() {
    const alert = useAlert();
    const [userDetails, setUserDetails] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isShow, setIsShow] = useState(false);
    const [pending, setPending] = useState(false);
    const [userTokens, setUserToken] = useState(null);
    const [freeTokens, setFreeTokens] = useState(false);
    const [freeTokenShow, setFreeTokenShow] = useState(false);
    const [userId, setUserId] = useState(null);
    const [usersPopup, setUsersPopup] = useState(false);
    const [tokensPopup, setTokensPopup] = useState(false);
    const [userIds, setUserIds] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [showModal, setShowModal] = useState(null);
    const [showPopUp, setShowPopUp] = useState(null);
    const [userTokensPopup, setUserTokensPopup] = useState(null);
    const [organizationList, setOrganizationsList] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [roleModal, setRoleModel] = useState(false);
    const [transactionModal, setTransactionModal] = useState();
    const [searchText, setSearchText] = useState('');

    const modal = (data) => {
        if (data) {
            setShowModal(data);
        } else {
            setShowModal(null);
        };
    };

    function handleFreeToken(data) {
        if (data) {
            setFreeTokenShow(data?.id);
        } else {
            setFreeTokenShow(null);
        };
    };

    const handleModal = (row) => {
        setTransactionModal(!transactionModal)
        setUserData(row);
    };

    const handleModel = () => {
        setIsShow(!isShow);
    };

    const handelRolePop = () => {
        setRoleModel(!roleModal)
    }

    const tokenShow = () => {
        setUserToken(!userTokens);
        setFreeTokens(false);
    };

    const exportTokensPopup = (props) => {
        // setUserOrgIds(props)
        setTokensPopup(!tokensPopup);
        if (props === 'close') {
            setUserIds(null)
        };
    };

    const exportUsersPopup = () => {
        setUsersPopup(!usersPopup);
    };

    useEffect(() => {
        getItemUsers();
        getOrganizationsList();
    }, []);

    const onSuccess = () => {
        setRefetch(true);
        getItemUsers();
    };

    const getItemUsers = async () => {
        setPending(true);
        const response = await CallApiHandler(
            Global.BUSSINESSLITE_USERS,
            undefined
        );
        const responseData = response?.data;
        if (responseData) {
            setUserDetails(responseData);
            setUsersList(responseData);
            setSelectedOrganization(null);
            setUserIds(null);
            setSearchText('');
        }
        setPending(false);
    };

    const itemColumns = [
        {
            name: <th>Name</th>,
            selector: (row) => row?.displayName,
            sortable: true,
        },
        {
            name: <th>Email</th>,
            selector: (row) => row?.email,
            sortable: true,
            cell: (row) => (
                <div>
                    <div>{DataHandler.isStringValue(row?.email)}</div>
                    <div>{DataHandler.isStringValue(row?.contactNumber)}</div>
                </div>
            ),
        },
        {
            name: <th>Organisation</th>,
            selector: (row) => row?.organisation,
            sortable: true,
        },
        {
            name: <th>Wallet Name</th>,
            selector: (row) => row?.walletname,
            sortable: true,
        },
        {
            name: <th>Total Token Generated</th>,
            selector: (row) => row?.tokensGenerated,
            sortable: true,
            cell: (row) => (
                <div className="card-body py-0">
                    <p className="card-text">{DataHandler.isIntegerValue(row?.tokensGenerated)} <span>/{DataHandler.isIntegerValue(row?.totalToken)}</span></p>
                </div>
            ),
        },
        {
            name: <th>Creation Date</th>,
            selector: (row) => row?.CreateDate,
            sortable: true,
            cell: row => (<>{getDateFormat(row?.CreateDate)}
            </>),
        },
        {
            name: <th>Activity Date</th>,
            selector: (row) => row?.activitydate,
            sortable: true,
            cell: row => (<>{getDateFormat(row?.activitydate)} {getTimeFormat(row?.activitydate)} </>)
        },
        {
            name: <th>Role</th>,
            selector: (row) => row?.role,
            sortable: true,
            cell: row => (<>{row?.role === "User" ? "Manufacturer" : "Company Admin"} </>)
        },
        {
            name: <th>Status</th>,
            selector: (row) => row?.isActive,
            sortable: true,
            cell: row => (
                <span data-tooltip={DataHandler.isStringValue(row?.status)}>
                    <span className='ellipsis'>
                        {DataHandler.isStringValue(row?.status)?.toLocaleLowerCase() === 'active' ?
                            <span className="logged-in">●</span> :
                            DataHandler.isStringValue(row?.status)?.toLocaleLowerCase() === 'inactive' ?
                                <span className="logged-out">●</span> :
                                <span className="pending-out">●</span>}
                    </span>
                </span>),
            center: true
        },
        {
            cell: row => (<>
                {row?.status && row?.status !== "Pending" ?
                    <div className="dropdown dropend">
                        <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical" color="rgba(107, 114, 128, 1)" pointerEvents="none"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                        </a>
                        <ul className="dropdown-menu pointer">
                            <li onClick={() => { setUserToken(row?.id) }} className={`drop-list ${DataHandler.isBooleanValue(row?.isActive) ? '' : 'disable-button'}`}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.FREE_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    Free Months
                                </a>
                            </li>
                            <li onClick={() => handleFreeToken(row)} className={`drop-list ${DataHandler.isBooleanValue(row?.isActive) ? '' : 'disable-button'}`}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.FREE_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    Free Tokens
                                </a>
                            </li>
                            <li onClick={() => { setUserToken(row?.id); setFreeTokens(true); }} className={`drop-list ${DataHandler.isBooleanValue(row?.isActive) ? '' : 'disable-button'}`}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.FREE_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    Free Single Use Tokens
                                </a>
                            </li>
                            <li className={`drop-list ${row?.availableTokenCount === 0 ? "disable-button" : ""}`} onClick={() => selectedRowId(row)}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.DOWNLOAD_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    Download
                                </a>
                            </li>
                            <li className="drop-list" onClick={() => setShowPopUp(row)}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.REFRESH_ICON} alt="" className='drop-list-img' /> &nbsp;
                                    {DataHandler.isStringValue(row?.isActive) ? 'Deactivate' : 'Activate'}
                                </a>
                            </li>
                            <li className={`drop-list ${row?.availableTokenCount === 0 ? "disable-button" : ""}`} onClick={() => setUserTokensPopup(encryptData(row?.id))}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.FREE_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    View Tokens
                                </a>
                            </li>
                            <li className="drop-list" onClick={() => modal(row)}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.SETTING_LOGO} alt="" className='drop-list-img' /> &nbsp;
                                    Attachment Configuration
                                </a>
                            </li>
                            {/* <li className={row?.tokensGenerated === 0 ? "disable-button" : ""}><a  href="#">Clear All Tokens</a></li> */}
                            <li className={`drop-list ${row?.isActive === false ? "disable-button" : ""}`} onClick={() => setRoleModel((row))}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.FREE_TOKEN} alt="" className='drop-list-img' /> &nbsp;
                                    {DataHandler.isStringValue(row?.role) === "User" ? "Assign  Admin Access" : 'Remove Admin Access'}
                                </a>
                            </li>
                            <li className={`drop-list ${row?.totalToken === 0 ? "disable-button" : ""}`} onClick={() => handleModal(row)}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.SETTING_LOGO} alt="" className='drop-list-img' /> &nbsp;
                                    Transaction History
                                </a>
                            </li>
                            <li className="drop-list" onClick={() => setUserId(row?.id)}>
                                <a href="#" className="dropdown-item">
                                    <img src={IMAGE_TYPES.ACTIVITY} className="drop-list-img" />&nbsp;
                                    View Activities
                                </a>
                            </li>
                        </ul>
                    </div> : <></>}
            </>),
            allowOverflow: true,
            button: true,
            width: '7%'
        }
    ];

    const selectedRowId = ({ orgId }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [orgId];
        setUserIds(selectedUsers);
        exportTokensPopup();
    };

    const onSelectedRowsChange = ({ selectedRows }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [];
        for (let index = 0; index < selectedRows?.length; index++) {
            let orgIdData = selectedRows?.[index]["orgId"]
            selectedUsers.userIds[index] = orgIdData;
        };
        setUserIds(selectedUsers);
    };

    const handleSearchData = (value) => {
        const searchData = usersList.filter(obj => obj?.displayName?.toLocaleLowerCase()?.includes(value)
            || obj?.email?.toLocaleLowerCase()?.includes(value) || obj?.organisation?.toLocaleLowerCase()?.includes(value)
            || obj?.tokensGenerated?.toString()?.toLocaleLowerCase()?.includes(value)
        );
        if (searchData && value) {
            setUserDetails(searchData)
        } else {
            setUserDetails(usersList);
        };
    };

    const rowDisabledCriteria = row => row?.tokensGenerated === 0;

    const handleUserStatus = async () => {
        setPending(true);
        const userStatus = DataHandler.isBooleanValue(showPopUp?.isActive);

        const variables = {
            userId: showPopUp?.id,
            statusId: userStatus ? 0 : 1
        };
        let request = getRequestForApi(Global.HANDLE_USER_STATUS, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(`user ${userStatus ? 'deactivated' : 'activated'} successfully`);
                setOrganizationsList(null);
                setSelectedOrganization(null);
                onClose();
                getItemUsers();
                getOrganizationsList();
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        });
    };

    const getOrganizationsList = async () => {
        setPending(true);
        let request = getRequestForApi(Global.READ_ORGANISATIONS, undefined, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201 && response?.data?.error === 201) {
                let responseData = response?.data?.response?.data;
                responseData.unshift({ id: '', name: 'All Organisations' })
                setOrganizationsList(responseData);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        });
    };


    const ChangeRole = async (e) => {
        setPending(true);

        let variables = {
            userId: roleModal?.id,
            role: DataHandler.isStringValue(roleModal?.role === "CompanyAdmin" ? "User" : "CompanyAdmin")
        }
        let request = getRequestForApi(Global.CHANGE_ADMIN_ROLE, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show(` ${roleModal?.role === "CompanyAdmin" ? 'Admin role removed' : 'Admin role assigned '} successfully`);
                handelRolePop()
                onClose();
                getItemUsers();
                getOrganizationsList();
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    }

    const onClose = () => {
        setShowPopUp(null);
    };

    const handleSearchClick = async (id) => {
        setSelectedOrganization(id);
        setPending(true);
        let variables = {};
        variables.id = id;
        const response = await CallApiHandler(
            Global.BUSSINESSLITE_USERS,
            variables
        );
        const responseData = response?.data;
        if (responseData) {
            setUserDetails(responseData);
            setUsersList(responseData);
            setUserIds(null);
        }
        setPending(false);
    };

    return (
        <>

            {<CustomLoader pending={pending} />}
            <CardSection source={sourceName.businessLite} refetch={refetch} setRefetch={setRefetch} />
            <section className="table-section-listing  pt-0">
                <div className="row table-row-padding">
                    {/* <div className="col-lg-4 d-flex justify-content-initial gap-4">
                    </div> */}
                    <div className="col-lg-12">
                        <div className="input-group rounded d-flex justify-content-end gap-3">
                            {DataHandler.isArrayValue(organizationList).length !== 0 && <div className="select-organization">
                                <select onChange={(event) => handleSearchClick(event.target.value)} value={DataHandler.isStringValue(selectedOrganization)}>
                                    {DataHandler.isArrayValue(organizationList)?.map((org) => {
                                        return <option key={org?.id} value={org?.id}>{org?.name}</option>
                                    })}
                                </select>
                            </div>}
                            <div className="input-group mb-3 search-box-width">
                                <input
                                    type="search"
                                    className="form-control search-bar-border border-dark"
                                    placeholder="Search ......"
                                    aria-label="Recipient's username"
                                    value={DataHandler.isStringValue(searchText)}
                                    onChange={(event) => {
                                        setSearchText(event.target.value);
                                        handleSearchData(event.target.value.toLocaleLowerCase())
                                    }}
                                    autoComplete="off"
                                />
                            </div>
                            <div onClick={() => exportUsersPopup()}>
                                <button type="button" className="btn btn-primary d-flex align-items-center">
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.PEOPLENEW_LOGO}
                                        alt=""
                                    />
                                    Export Users
                                </button>
                            </div>
                            <div
                                className={DataHandler.isArrayValue(userIds?.userIds).length !== 0 ? "" : "disable-button"}
                                onClick={() => exportTokensPopup()}
                            >
                                <button type="button" className="btn btn-primary d-flex align-items-center">
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.COIN}
                                        alt=""
                                    />
                                    Export Tokens
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={() => handleModel()}
                                >
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.SETTING_NEW_LOGO}
                                        alt=""
                                    />
                                    Token Configuration
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={() => modal("show")}
                                >
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.SETTING_NEW_LOGO}
                                        alt=""
                                    />
                                    Attachment Configuration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"60vh"}
                        data={userDetails}
                        columns={itemColumns}
                        selectableRows
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={pending}
                    />
                </div>
            </section>

            <PlansModal isShow={isShow} onClose={handleModel} source={sourceName.businessLite} userType={roleTypes.MANUFACTURER} />
            <UserToken isShow={userTokens} userId={userTokens} freeTokens={freeTokens} onClose={tokenShow} onSuccess={onSuccess} source={sourceName.businessLite} />
            <FreeUserToken isShow={freeTokenShow} userId={freeTokenShow} onClose={handleFreeToken} onSuccess={onSuccess} />
            <Confirmation isShow={tokensPopup} userIds={DataHandler.isArrayValue(userIds)} onSuccess={getItemUsers} onClose={exportTokensPopup} source={sourceName.businessLite} />
            <ExportUsers isShow={usersPopup} onSuccess={getItemUsers} onClose={exportUsersPopup} source={sourceName.businessLite} />
            <AttachmentConfig showModal={showModal} onClose={modal} userType={roleTypes.MANUFACTURER} />
            <CustomConfirmation onClose={onClose} onSubmit={handleUserStatus} showPopUp={showPopUp} message={`Are you sure you want to ${DataHandler.isBooleanValue(showPopUp?.isActive) ? 'deactivate' : 'activate'} this user?`} />
            <ViewTokens showPopUp={userTokensPopup} setUserTokensPopup={setUserTokensPopup} />
            <TransactionHistory usersList={usersList} transactionModal={transactionModal} onClose={handleModal} onSuccess={getItemUsers} setUsersDetails={setUserData} userDetails={userData} />
            <GrantAdmin isShowPop={roleModal} onClose={handelRolePop} onSubmit={ChangeRole} message={`Are you sure you want to ${DataHandler.isStringValue(roleModal?.role) === "User" ? 'assign Company Admin' : 'remove Company Admin'}  role?`} />
            {userId && <ViewActivity userId={userId} onClose={setUserId} />}
        </>
    );
}
export default BussinessLite;
