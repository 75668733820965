import React, { useEffect, useState } from 'react'
import { CallApiHandler } from '../../utility-files/custom-hooks/CallApiHandler';
import * as Global from '../../constants/Global'
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import * as CustomInput from '../../utility-files/custom-input/CustomInput'
import { alertMessages, alertTypes, errorMessages } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import CustomLoader from '../custom-modules/CustomLoader';
import { PLACE_HOLDER_TEXT } from '../../utility-files/default-values/DefaultValues';

function ForgotPassword(props) {
    const alert = useAlert();
    const [passwordReset, setPasswordReset] = useState(null);
    const { showModal, setShowModal } = props;
    const [pending, setPending] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    useEffect(() => {
        setSubmitted(false)
    }, [passwordReset])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!passwordReset?.isEmailValid) {
            setSubmitted(true);
            return
        }
        setPending(true);
        const variables = {};
        variables.email = DataHandler.isStringValue(passwordReset?.['email']);
        const response = await CallApiHandler(Global.FORGOT_PASSWORD, variables)
        const data2 = response?.data;
        const error = response?.error;
        if (data2) {
            alert.show(alertMessages.RESETPASSWORD, { type: alertTypes.SUCCESS });
            setShowModal(false);
            setPasswordReset(null)
        };
        if (error) {
            alert.show(error, { types: alertTypes.ERROR });
        };
        setPending(false);
    }
    return (
        <>
            {<CustomLoader pending={pending} />}
            <div className={`modal ${showModal ? 'modal-show' : 'fade'}`} id={showModal} tabIndex="0" aria-labelledby={showModal} aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="forgotPasswordModalLabel">Forgot Password</h5>
                            <button type="button" className="small btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setShowModal(false); setPasswordReset(null); setSubmitted(false) }} />
                        </div>
                        <div className="modal-body">
                            <forma>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email Address</label>
                                    <div className="input-box d-flex"></div>
                                    <CustomInput.Email
                                        setData={setPasswordReset}
                                        keyName={"email"}
                                        value={DataHandler.isStringValue(passwordReset?.['email'])}
                                        className="form-control"
                                        id="email-name"
                                        placeholder={PLACE_HOLDER_TEXT.email}
                                    />
                                    <div />
                                    {isSubmitted && <p className='warninig'>{passwordReset && !passwordReset?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p>}

                                </div>
                                <button type="submit" className="btn btn-primary" onClick={(e) => onSubmit(e)}>Reset Password</button>
                            </forma>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
