import React, { } from 'react'
import { Link } from 'react-router-dom'
import { UAT_URL, path } from '../utility-files/helper-function/HelperFunction'
import { useLocation } from 'react-router-dom';
import { getStorageData, storageKey, storageType } from '../utility-files/storege-util/StorageHandling';
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues';

function Header() {
    const location = useLocation();
    const loginPage = location?.pathname === path.LOGIN;
    const UAT_SERVER = window.location.origin === UAT_URL;
    const isLoggedIn = getStorageData(storageType, storageKey.TOKEN) ? getStorageData(storageType, storageKey?.TOKEN) : getStorageData(!storageType, storageKey?.TOKEN) ? true : false;

    return (
        <>
            <div className="header">
                <header id="headerCntr">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="logo d-flex align-items-center justify-content-between w-100">
                                    <Link to={isLoggedIn ? path.HOME : path.LOGIN}>
                                        <img src={IMAGE_TYPES.LOGO_IMAGE} className="logo" alt="" />
                                    </Link>
                                    {UAT_SERVER && <img src={IMAGE_TYPES.UAT} className="logo" alt="" height='26px' width='60px' />}
                                </div>
                                <a href="javascript:void(0);" className="toggle_menu"> <i className="bi bi-list"></i> </a>
                                {/* <button className="btn btn-outline-success my-2 my-sm-0 px-4" type="submit" onClick={() =>logout()}>Logout</button> */}
                                <div className="nav-content">
                                    {loginPage && isLoggedIn && <Link to={path.SIGNUP}>
                                        <button className="btn btn-outline-success my-2 my-sm-0 px-4" type="submit">Sign up</button>
                                    </Link>}

                                    {!loginPage && !isLoggedIn && <Link to={path.LOGIN}>
                                        <button className="btn btn-outline-success my-2 my-sm-0 px-4" type="submit">Login</button>
                                    </Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Header