import React from 'react'
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import { getFormatedDate, maxDate } from '../../utility-files/date-util/DateHandling';

function Filter({ showFilter, setShowFilter, feedbackList, setFeedbackData, setFeedbackList, filterDate, setFilterDate, listFeedback }) {
    const alert = useAlert();
    const handleClose = (event) => {
        const { id } = event.target;
        if (id === 'Clear') {
            listFeedback();
            alert.show('Filter removed successfully', { type: alertTypes.SUCCESS });
        };
        setShowFilter(!showFilter);
        setFilterDate(null);
    };

    const handleDateChange = (event) => {
        const { id, value } = event.target;
        if (id === 'start') {
            setFilterDate({ ...filterDate, [id]: value });
        } else {
            if (!filterDate?.start) {
                alert.show('Please select from date', { type: alertTypes.INFO })
                return;
            };
            setFilterDate({ ...filterDate, [id]: value });
        };
    };

    const applyFilter = () => {
        const resultProductData = feedbackList.filter(
            function (a) {
                if (filterDate?.start && filterDate?.end) {
                    return (getFormatedDate(a.createdAt)) >= filterDate?.start && (getFormatedDate(a.createdAt)) <= filterDate?.end
                } else {
                    return getFormatedDate(a.createdAt) >= (filterDate?.start);
                }
            }
        );
        setFeedbackList(resultProductData);
        setFeedbackData(resultProductData)
        setShowFilter(!showFilter);
        alert.show('Filter applied successfully', { type: alertTypes.SUCCESS });
    };
    return (
        <div className={`modal ${showFilter ? "modal-show" : "fade"} bd-example-modal-sm`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header hide-border d-flex justify-content-between align-items-center">
                        <div>
                            <label htmlFor="start">From date:</label>
                            <input type="date" id="start" name="trip-start" value={DataHandler.isStringValue(filterDate?.start)} max={DataHandler.isStringValue(filterDate?.end) || DataHandler.isStringValue(maxDate())} onChange={(event) => handleDateChange(event)} />
                        </div>
                        <div>
                            <label htmlFor="start">To date:</label>
                            <input type="date" id="end" name="trip-start" value={DataHandler.isStringValue(filterDate?.end)} min={DataHandler.isStringValue(filterDate?.start)} max={DataHandler.isStringValue(maxDate())} onChange={(event) => handleDateChange(event)} />
                        </div>

                    </div>
                    <div className="modal-footer d-flex justify-content-end mt-2">
                        <button type="button" className={`btn btn-primary ${filterDate?.start ? "" : "disabled"}`} onClick={() => applyFilter()}>Apply</button>
                        <button type="button" className="btn btn-secondary" id={(filterDate?.start || filterDate?.end) ? 'Clear' : 'Cancel'} onClick={(event) => handleClose(event)}>{(filterDate?.start || filterDate?.end) ? 'Clear' : 'Cancel'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter