import { utils, writeFileXLSX } from "xlsx";
import { callHttpRequest } from "../api-util/HttpRequest";
import { jwtDecodeData } from "../data-encryption-util/DataEncryption";
import * as DataHandling from "../data-util/DataHandler";
import * as StorageHandling from "../storege-util/StorageHandling";
import { getCurrentDate, getCurrentTime } from "../date-util/DateHandling";

export const UAT_URL = 'https://admin.testqedvault.com'
export const removeList = ['event', 'fileUrl', 'uploadEventType', 'isValid'];
export const sourceName = {
    businessLite: 'Business_Lite',
    consumerLite: 'Consumer_Lite',
    feedback: 'Feedback',
    reports: 'Reports'
}
export const exportUserFileName = (source, event) => {
    let fileName;
    if (source === sourceName.businessLite) {
        fileName = sourceName.businessLite + event;
    } else if (source === sourceName.consumerLite) {
        fileName = sourceName.consumerLite + event;
    } else if (source === sourceName.reports) {
        fileName = sourceName.reports + '_' + event;
    } else {
        fileName = sourceName.feedback;
    };
    return fileName + `_${getCurrentDate()}_${getCurrentTime()}.${'xlsx'}`;
}

export const exportVaultName = (source) => {
    let fileName;
    if (source === sourceName.businessLite) {
        fileName = sourceName.businessLite;
    } else if (source === sourceName.feedback) {
        fileName = sourceName.feedback;
    } else if (source === sourceName.reports) {
        fileName = sourceName.reports;
    } else {
        fileName = sourceName.consumerLite;
    };
    return fileName;
}

export const imageKeysRemove = ['id', 'assetIndex', 'key', 'attribute1', 'attribute2', 'attribute3', 'isActive', 'createBy', 'createdAt', 'createdDateOnly', 'timestamp']
export const roleTypes = {
    MANUFACTURER: 'Manufacturer',
    CUSTOMER: 'Customer'
};


export const REPORT_FOR = [
    {
        id: '',
        name: 'Select report for'
    },
    {
        id: 'Update',
        name: 'User Name Updates'
    },
    {
        id: 'Registration',
        name: 'New Registrations'
    },
];


export const path = {
    LOGIN: '/login',
    HOME: '/',
    ITEMS: '/items',
    CONFIRM_PASSWORD: '/confirm_password/',
    ACCEPT_INVITE: '/accept_invite/',
    TOKENS: '/tokens',
    SIGNUP: '/signup',
    ADDITEM: '/AddItem',
    MINIADD: '/miniADD',
    ITEM_DETAILS: '/item-details',
    FILTER: '/CustomFilter',
    ERROR: '/error',
    MFA: '/mfa',
    FAQ: '/faq',
    WELCOME: '/welcome',
    CHECKOUT: '/checkout',
    PAYMENTS: '/payments',
    SUCCESS: '/success',
    CANCEL: '/cancel',
    CONSUMER_LITE: '/consumer_lite',
    BUISSNESS_LITE: '/buissness_lite',
    FEEDBACK: '/feedback',
    REPORT: '/report',
    USERLIST: '/user',
    VIEW_TOKENS: '/user_tokens',
    WALLETNAME: '/walletName'
};

export const PATH_SLUG = {
    MFA: '/:id/:rem',
    CONFIRM_PASSWORD: ':email',
    ACCEPT_INVITE: ":index/:inviteCode/:email",
    ITEM_DETAILS: '/:index/:id',
    PAYMENTS: '/:secret'
}

export const alertTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error'
};

export const FILTER_LIST = [
    {
        id: '2',
        name: 'All'
    },
    {
        id: '0',
        name: 'Recently added'
    },
    {
        id: '1',
        name: 'Last Month'
    },
];

export const alertMessages = {
    LOGGEDIN: 'user logged in',
    SIGNUP: 'user signed up',
    ENTER_CREDENTIALS: 'Please enter your credentials',
    ENTER_COMPANYNAME: 'Please enter your Company name',
    ENTER_NUMBER: 'Please enter your phone number',
    ENTER_EMAIL: 'Please enter your email',
    ENTER_PASSWORD: 'Please enter your password',
    ENTER_CONFIRMPASSWORD: "Password  and confirm password should be same",
    UPDATE_PASSWORD: 'User password updated successfully',
    RESETPASSWORD: "Reset Password link sent your email.",
    FORGOTPASSD_MESSAGE: "Password reset successfully",
    ADD_PLAN_SUCCESS: 'Plan added successfully',
    UPDATE_PLAN_SUCCESS: 'Plan updated successfully',
    DELETE_PLAN_SUCCESS: 'Plan deleted successfully',
    FREE_TOKENS_SUCCESS: 'free tokens updated successfully',
    ATTACH_SUCCESS: "File confrigation save successfully."
};

export const errorMessages = {
    REQUIRED_FIELD: 'This field is required',
    ENTER_VALID_EMAIL: "Please enter a valid email",
    ENTER_NAME: "Please enter your name",
    PASSWORD: 'Password length must be eight or more characters and contain at least one numeric digit, one uppercase , one lowercase letter and one Special character (m#P52s1$V).',
    ENTER_COMPANYNAME: "Please enter company name",
    TOKENS: "Token number must be greater than zero",
    NAME: " This wallet name is already used ."
}

export const modelType = {
    ADD_ITEM: "ADD_ITEM",
}

export const copyText = (text) => {
    navigator.clipboard.writeText(text).then(function () {
    }, function (err) {
        console.error(err);
    });
};

export const getApiResponse = async (request) => {
    let res = null;
    await callHttpRequest(request).then(response => {
        if (response?.status === 201) {
            res = response?.data;
        }
    }).catch((err) => {
        res = err;
    });
    return res;
};

export const userDetails = () => {
    if (DataHandling.isObjectValue(StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) ? StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) : StorageHandling.getStorageData(!StorageHandling.storageType, StorageHandling.storageKey.TOKEN))) {
        return jwtDecodeData(StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) ? StorageHandling.getStorageData(StorageHandling.storageType, StorageHandling.storageKey.TOKEN) : StorageHandling.getStorageData(!StorageHandling.storageType, StorageHandling.storageKey.TOKEN));
    }
};

export const getBuffer = (buffer) => {
    let bufferNew = buffer?.split(",");
    if (bufferNew.length >= 1) {
        bufferNew = bufferNew[1];
    }
    return bufferNew;
};

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            let baseURL = fileReader.result
            resolve(baseURL);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const handleFileUpload2 = async (event, uploadType, itemIndex, attachmentsList) => {
    let uploadFilesData = [];
    if (attachmentsList && attachmentsList?.length !== 0) {
        attachmentsList.forEach(element => {
            element.isValid = true;
        })
        uploadFilesData = attachmentsList;
    };
    let uploadDataType = {};
    uploadDataType.event = event;
    uploadDataType.byteArray = '';
    uploadDataType.fileName = '';
    uploadDataType.fileUrl = '';
    uploadDataType.uploadType = FILE_HANDLER.ADD;
    uploadDataType.uploadEventType = uploadType;
    if (uploadType === 'profile') {
        uploadDataType.moduleId = userDetails()?.id;
        uploadDataType.module = "User";
        uploadDataType.ordBy = 0;
    } else {
        uploadDataType.itemIndex = itemIndex;
        uploadDataType.fileType = uploadType;
        uploadDataType.ordBy = 0;
    };
    let filesData = uploadDataType.event.target.files;
    if (uploadDataType.uploadEventType === 'profile') {
        let buffer = await convertBase64(filesData[0]);
        return [{
            ...uploadDataType,
            fileName: filesData[0].name,
            byteArray: getBuffer(buffer),
            fileUrl: buffer,
            isValid: checkValidUpload(filesData[0].name, uploadDataType.uploadEventType)
        }];
    } else {
        for (let index = 0; index < filesData?.length; index++) {
            let image = filesData[index];
            let fileName = filesData[index].name;
            let buffer = await convertBase64(image);
            uploadFilesData.push({
                ...uploadDataType,
                byteArray: getBuffer(buffer),
                fileUrl: buffer,
                fileName: fileName,
                isValid: checkValidUpload(fileName, uploadDataType.uploadEventType)
            });
        }
        uploadFilesData.forEach((element, index) => {
            element.ordBy = index;
            element.uploadType = element.uploadType ? element.uploadType : FILE_HANDLER.ADD;
            imageKeysRemove.forEach(data => {
                delete element[data];
            });
        });
        return uploadFilesData;
    };
};


export const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = path.HOME;
};

export const downloadXMLfile = (data, filename) => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const TOKENS_JSON = [
    {
        upcCode: "TEST01",
        serialNo: "TEST01",
        otherIdentifierCode: "TEST01",
        description: "Test item one",
        notes: "TEST01",
    },
    {
        upcCode: "",
        serialNo: "",
        otherIdentifierCode: "",
        description: "Test item two",
        notes: "",
    },
    {
        upcCode: "TEST03",
        serialNo: "TEST03",
        otherIdentifierCode: "TEST03",
        description: "Test item three",
        notes: "TEST03",
    },
    {
        upcCode: "",
        serialNo: "",
        otherIdentifierCode: "",
        description: "Test item four",
        notes: "",
    }
];

export const navbar_links = [
    {
        id: 1,
        name: 'Tokens'
    },
    {
        id: 5,
        name: 'Certificates'
    },
    {
        id: 4,
        name: 'Transferred'
    },
    {
        id: 3,
        name: 'Discarded'
    },

    {
        id: 2,
        name: 'Unclaimed'
    },
    {
        id: 0,
        name: 'All'
    }
];

export const jsonToXLSXFile = async (array, name, vaultName) => {
    let jsonArray = [];
    let fileName = '';
    if (DataHandling.isArrayValue(array)?.length !== 0 && DataHandling.isStringValue(name) !== '') {
        jsonArray = DataHandling.isArrayValue(array);
        fileName = DataHandling.isStringValue(name)
    } else {
        jsonArray = TOKENS_JSON;
        fileName = `QED_lite_token${getCurrentDate()}.xlsx`
    };
    const ws = utils.json_to_sheet(jsonArray);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, vaultName);
    writeFileXLSX(wb, fileName);
};

export const FILE_HANDLER = {
    ADD: 0,
    UPDATE: 1,
    DELETE: 2
};

export const checkValidUpload = (fileName, uploadDataType) => {
    var splitString = fileName.split(".");
    var fileExt = splitString[splitString.length - 1];
    let isValid = false;
    if (uploadDataType.toLowerCase() === 'profile' || uploadDataType.toLowerCase() === 'file') {
        if (fileExt.toLowerCase() === "jpeg" || fileExt.toLowerCase() === 'jfif' || fileExt.toLowerCase() === "jpg" || fileExt.toLowerCase() === "gif" || fileExt.toLowerCase() === "png") {
            isValid = true;
        };
    } else {
        if (fileExt.toLowerCase() === "pdf" || fileExt.toLowerCase() === "doc" || fileExt.toLowerCase() === "docx" || fileExt.toLowerCase() === "xls" || fileExt.toLowerCase() === "xlsx" || fileExt.toLowerCase() === "txt") {
            isValid = true;
        };
    };
    return isValid;
}