const storageType = 'localStorage';

const storageKey = {
    TOKEN: 'token-admin',
};

const clearData = () => {
    localStorage.clear();
}

const setStorageData = (storage, key, data) => {
    if (storage === storageType) {
        localStorage.setItem(key, data);
    } else {
        sessionStorage.setItem(key, data);
    }
};

const getStorageData = (storage, key) => {
    if (storage === storageType) {
        return localStorage.getItem(key);
    } else {
        return sessionStorage.getItem(key);
    }
};

export {
    storageType,
    storageKey,
    clearData,
    setStorageData,
    getStorageData
}