
const $ = window.jQuery = require('jquery')

$(document).on('click', '.profile-image', function () {
  $('#SideNavToggle').modal('show');
});

$(document).on('click', '.profile-Show', function () {
  $('#Profile').modal('show');
  $('#SideNavToggle').modal('hide');
});

// $(document).on('click', '.btn-close-profile', function () {
//   $('#SideNavToggle').modal('show');
// });

$(document).on('click', '.change_name', function () {
  $('#ChangeName').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.change_password', function () {
  $('#ChangePassword').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.profile_image', function () {
  $('#ImageUpload').modal('show');
  $('#Profile').modal('hide');
});

$(document).on('click', '.close-name-modal', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.close-password-modal', function () {
  $('#Profile').modal('show');
});

$(document).on('click', '.image_upload_close', function () {
  $('#Profile').modal('show');
});



$(document).on('click', '.close-name-email', function () {
  $('#InviteSend').modal('show');

});


$(document).on('click', '.Attech_Config', function () {
  $('#AttechConfig').modal('hide');
});
// $(document).on('click', '.close', function () {
//   $('#ImageUpload').modal('show');
// });SideNavToggle

$(document).on('click', ".noBtn", function () {
  $("#deleteToken").removeClass('show');
});

