import React, { } from "react";
import ChangeName from "../custom-popup-modal/ChangeName";
import ChangePassword from "./ChangePassword";
import { IMAGE_TYPES } from "../../utility-files/default-values/DefaultValues"
import ImageUpload from "./ImageUpload";
import * as DataHandler from '../../utility-files/data-util/DataHandler';
function Profile(props) {
    const { userData, onSuccess } = props
    return (
        <>
            <div className="modal fade" id="Profile" tabIndex="-1" aria-labelledby="Profile" aria-hidden="false"  data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-head">
                                <h5 className="modal-title" id="exampleModalLabel">Account</h5>
                            </div>
                            <div className="close">
                                <button type="button" className="small btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-4 profile_image">
                                    <div className="watch-img w-100 myaccountimg fileUploadModal d-flex align-items-center flex-column">
                                        <img className="profile-image-size" src={DataHandler.isStringValue(userData?.profileImageUrl) || IMAGE_TYPES.SKULL} alt="profile" />
                                        <a href="#">Edit</a>
                                    </div>
                                </div>
                                <div className="col-1">

                                </div>
                                <div className="col-7">
                                    <div className="modal-input">
                                        <label htmlFor="email">Name</label>
                                        <p>{userData?.displayName}</p>
                                        <a href="#" className="change_name text-decoration-none">Change Name</a>
                                    </div>
                                    <div className="modal-input">
                                        <label htmlFor="email">Password</label>
                                        <a href="#" className="change_password">Change Password</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChangeName userData={userData} onSucess={onSuccess} />
            <ChangePassword userData={userData} onSuccess={onSuccess}/>
            <ImageUpload userData={userData} onSuccess={onSuccess} />
        </>
    )
}
export default Profile