import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UAT_URL, path, userDetails } from '../utility-files/helper-function/HelperFunction'
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues'
import SideNavToggle from '../pages/custom-popup-modal/SideNavToggle';
import * as Global from '../constants/Global';
import * as DataHandler from '../utility-files/data-util/DataHandler';
import { CallApiHandler } from '../utility-files/custom-hooks/CallApiHandler';

function SideNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [headerData, setHeaderData] = useState('User List');
    const [pending, setPending] = useState(false);
    const UAT_SERVER = window.location.origin === UAT_URL;

    useEffect(() => {
        getUserDetails();
        showMenu();
    }, []);

    useEffect(() => {
        if (location.pathname === path.FEEDBACK) {
            setHeaderData('Feedback List');
        } else if (location.pathname === path.REPORT) {
            setHeaderData('Reports');
        }
        else if (location.pathname === "/") {
            setHeaderData('Business User List');
        }
        else if (location.pathname === path.CONSUMER_LITE) {
            setHeaderData('Consumer List');
        }
        else if (location.pathname === path.WALLETNAME) {
            setHeaderData('Protected Wallet Names');
        }
        else {
            setHeaderData('User List');
        }
    }, [location]);

    const showMenu = () => {
        let arrow = document.querySelectorAll(".arrow");
        for (var i = 0; i < arrow.length; i++) {
            arrow[i].addEventListener("click", (e) => {
                let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
                arrowParent.classList.toggle("showMenu");
            });
        }
        let sidebar = document.querySelector(".sidebar");
        let sidebarBtn = document.querySelector(".bx-menu");
        sidebarBtn.addEventListener("click", () => {
            sidebar.classList.toggle("close");
        });
    };

    const getUserDetails = async () => {
        setPending(true);
        const response = await CallApiHandler(Global.GET_USER_DETAILS, undefined);
        const userData = response?.data;
        if (userData) {
            setUserData(userData);
        };
        setPending(false);
    };

    return (
        <>
            <div className="sidebar">
                <div className="logo-details gap-2 pointer" onClick={() => navigate(path.HOME)}>
                    <img src={IMAGE_TYPES.QEDLOGO} alt="" />
                    <span className="logo_name">QED Admin</span>
                </div>
                <div className="void-sidenav"></div>
                <div className="para-nav px-4">
                    <p className='link_name'>Main</p>
                </div>
                <ul className="nav-links">
                    <li className={`sidenav-images ${location.pathname === path.HOME ? "sidenav-active" : ""}`}>
                        <Link to={path.HOME}>
                            <img src={IMAGE_TYPES.BUSSINESSLITE_LOGO} alt="" />
                            <span className="link_name">QED Business</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">QED Business</a></li>
                            </ul>
                        </Link>
                    </li>
                    <li className={`sidenav-images ${location.pathname === path.CONSUMER_LITE ? "sidenav-active" : ""}`}>
                        <Link to={path.CONSUMER_LITE}>
                            <img src={IMAGE_TYPES.PEOPLEIMAGE} alt="" />
                            <span className="link_name">QED Consumer</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">QED Consumer</a></li>
                            </ul>
                        </Link>
                    </li>
                    <li className={`sidenav-images ${location.pathname === path.USERLIST ? "sidenav-active" : ""}`}>
                        <Link to={path.USERLIST}>
                            <img src={IMAGE_TYPES.UESRS} alt="" />
                            <span className="link_name">Admin User's</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">Admin User's</a></li>
                            </ul>
                        </Link>
                    </li>
                    <li className={`sidenav-images ${location.pathname === path.FEEDBACK ? "sidenav-active" : ""}`}>
                        <Link to={path.FEEDBACK}>
                            <img src={IMAGE_TYPES.FEEDBACK} alt="" />
                            <span className="link_name">Feedback's</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">Feedback's</a></li>
                            </ul>
                        </Link>
                    </li>
                    <li className={`sidenav-images ${location.pathname === path.WALLETNAME ? "sidenav-active" : ""}`}>
                        <Link to={path.WALLETNAME}>
                            <img src={IMAGE_TYPES.BAN_ICON} alt="" />
                            <span className="link_name">Protected Wallet Names</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">Protected Wallet Names</a></li>
                            </ul>
                        </Link>
                    </li>
                    <li className={`sidenav-images ${location.pathname === path.REPORT ? "sidenav-active" : ""}`}>
                        <Link to={path.REPORT}>
                            <img src={IMAGE_TYPES.REPORT} alt="" />
                            <span className="link_name">Report's</span>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">Report's</a></li>
                            </ul>
                        </Link>
                    </li>
                </ul>
            </div>

            <section className="home-section home-sidenav-close">
                <div className="home-content">
                    <i className="bx bx-menu"></i>
                    <div className="home-nav">
                        <div className="home-nav-content">
                            <h5 className="m-0">{headerData}</h5>
                        </div>
                        <div className="home-nav-content-images">
                            {UAT_SERVER && <img src={IMAGE_TYPES.UAT} className="logo" alt="" height='26px' width='45px' />}
                            <img
                                className="profile-image pointer"
                                src={DataHandler.isStringValue(userData?.profileImageUrl) || (DataHandler.isStringValue(userDetails()?.profileImageUrl) || IMAGE_TYPES.SKULL)}
                            />
                        </div>
                    </div>
                </div>
                <hr className="horizontal-row" />
            </section>
            <SideNavToggle userData={userData} onSuccess={getUserDetails} />
        </>
    )
}

export default SideNav