import React from 'react'

function ShowPasswordIcon({ showPassword, setShowPassword }) {
    return (
        <>
            {showPassword ? <i className='bi bi-eye password-icon' onClick={() => setShowPassword(!showPassword)} /> :
                <i className='bi bi-eye-slash password-icon' onClick={() => setShowPassword(!showPassword)} />}
        </>
    )
}

export default ShowPasswordIcon