import React, { useState } from 'react'
import * as Global from "../../constants/Global"
import { CallApiHandler } from '../../utility-files/custom-hooks/CallApiHandler';
import * as CustomInput from "../../utility-files/custom-input/CustomInput"
import * as DataHandler from "../../utility-files/data-util/DataHandler"
import { DEFAULT_HOLDER_VAlUES, IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import { alertTypes, errorMessages } from '../../utility-files/helper-function/HelperFunction';
import $ from 'jquery';
import { useAlert } from 'react-alert';
import CustomLoader from '../custom-modules/CustomLoader';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
function ChangePassword(props) {
    const { userData, onSuccess } = props;
    const alert = useAlert();
    const [pending, setPending] = useState(false)
    const [userDetails, setUserDetails] = useState(userData);
    const[submitted,setSubmitted]=useState(false)
    const updateUserPassword = async () => {
        if (userDetails?.password == '' || userDetails?.confirmPassword == '') {
            setSubmitted(true);
            return;
        }
        let variables = {};
        variables.email = DataHandler.isStringValue(userDetails?.email);
        variables.currentPassword = DataHandler.isStringValue(userDetails?.currentPassword);
        variables.password = DataHandler.isStringValue(userDetails?.password);
        variables.confirmPassword = DataHandler.isStringValue(userDetails?.confirmPassword);
        let request = getRequestForApi(Global.GET_UPDATE_USERPASSWORD, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Password changed successfully", { type: alertTypes.SUCCESS });
                $('#ChangePassword').modal('hide');
                $('#Profile').modal('show');
                setUserDetails(null);
                setSubmitted(false);
                setPending(false);
                onSuccess();
            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    };

    const disableSubmit = () => {
        if (!userDetails?.currentPassword || !userDetails?.password || !userDetails?.confirmPassword) {
            return true;
        };
    };
  return (
        <>
            {<CustomLoader pending={pending} />}
            <div className="modal fade" id="ChangePassword" tabIndex="-1" aria-labelledby="ChangePassword" aria-hidden="false"  data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Change Password</h5>
                            <button type="button" className="small btn-close close-password-modal" data-bs-dismiss="modal" aria-label="Close" onClick={()=> setUserDetails(null)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-row">
                                <label htmlFor="password"> Current Password</label>
                                <div className="input-box d-flex">
                                    <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                                    <CustomInput.Password
                                        setData={setUserDetails}
                                        keyName={"currentPassword"}
                                        value={DataHandler.isStringValue(userDetails?.['currentPassword'])}
                                        className="form-control"
                                        id="password-name"
                                        placeholder="Enter your password here"
                                    />
                                </div>
                                <label htmlFor="password">New Password</label>
                                <div className="input-box d-flex">
                                    <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                                    <CustomInput.Password
                                        setData={setUserDetails}
                                        keyName={"password"}
                                        value={DataHandler.isStringValue(userDetails?.['password'])}
                                        className="form-control"
                                        id="password-name"
                                        placeholder="Enter your password here"

                                    />
                                </div>
                              {submitted &&  <p className='warninig'>{DataHandler.isStringValue(userDetails?.password) === '' ? errorMessages.REQUIRED_FIELD : ""}</p>}
                    {userDetails?.password && <p className='warninig'>
                        {!userDetails['isPasswordValid'] ? errorMessages.PASSWORD :
                            userDetails?.currentPassword && userDetails?.password && userDetails?.currentPassword === userDetails?.password ? 'Current and New password should not be same' : ''
                        }
                    </p>}
                                <label htmlFor="password"> Confirm Password</label>
                                <div className="input-box d-flex">
                                    <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                                    <CustomInput.Password
                                        setData={setUserDetails}
                                        keyName={'confirmPassword'}
                                        className={'form-control'}
                                        id="password-name"
                                        placeholder={"Enter your confirm password"}
                                        value={DataHandler.isStringValue(userDetails?.['confirmPassword'])}
                                    />
                                </div>
                                   {submitted &&   <p className='warninig'>{DataHandler.isStringValue(userDetails?.confirmPassword) === '' ? errorMessages.REQUIRED_FIELD : ""}</p> }
                    {userDetails?.confirmPassword && <p className='warninig'>
                        {!userDetails['isConfirmPasswordValid'] ? errorMessages.PASSWORD :
                            userDetails?.password && userDetails?.confirmPassword && userDetails?.password !== userDetails?.confirmPassword ? 'Password  and confirm password should be same' : ''
                        }
                    </p>}
                            </div>
                        </div>

                        <div className="modal-footer d-flex justify-content-between">
                            <a href="#"></a>
                            <button type="button"
                                className={`btn btn-primary ${disableSubmit() ? 'disable-button' : " "}`}
                                onClick={() => updateUserPassword()}
                            >{pending ? DEFAULT_HOLDER_VAlUES.loading : 'Change password'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword