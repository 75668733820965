import React, { useEffect, useState, Suspense } from 'react'
import * as Global from '../../constants/Global'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { isStringValue, isIntegerValue, isArrayValue } from '../../utility-files/data-util/DataHandler';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { GET_DISPLAY_TIME_FORMAT, getDateFormat } from '../../utility-files/date-util/DateHandling';
import NoRecord from './NoRecord'
import CustomLoader from '../custom-modules/CustomLoader';
import moment from 'moment';
import CommonTable from '../../custom-ui/CommonTable';

function TransactionHistory({ transactionModal, onSuccess, userDetails, setUsersDetails, onClose }) {
    const [transactionData, setTransactionData] = useState(null);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        if (isStringValue(userDetails?.['id']) && transactionModal) {
            getTransactionHistory();
        }
    }, [isStringValue(userDetails?.['id']), transactionModal]);


    const getTransactionHistory = async () => {
        let variables = {
            userId: isStringValue(userDetails?.['id'])
        };
        setPending(true);
        let request = getRequestForApi(Global.GET_USER_TRANSACTIONS, variables, methodType.POST);
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data
                setTransactionData(responseData)
                setUsersDetails(null);
                onSuccess();
            };
            setPending(false)
        }).catch((err) => {
            console.log("err", err)
            setPending(false);
        })
    };
    const handleClose = () => {
        onClose();
    }
    const itemColumns = [
        {
            name: <th>Plan Name</th>,
            selector: row => row?.planName,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.planName)}
            </>),
            wrap: true
        },
        {
            name: <th>Description</th>,
            selector: row => row?.planDescription,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.planDescription)}
            </>),
            wrap: true
        },
        {
            name: <th>Tokens</th>,
            selector: row => row?.noOfTokens,
            sortable: true,
            cell: row => (<>
                {isIntegerValue(row?.noOfTokens)}
            </>),
            wrap: true
        },
        {
            name: <th>Amount</th>,
            selector: row => row?.amount,
            sortable: true,
            cell: row => (<>
                {'$' + isStringValue(row?.amount)}
            </>),
            wrap: true
        },
        {
            name: <th>Status</th>,
            selector: row => row?.status,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.status)}
            </>),
            wrap: true
        },
        {
            name: <th>Reason</th>,
            selector: row => row?.reason,
            sortable: true,
            cell: row => (<>
                {isStringValue(row?.reason)}
            </>),
            wrap: true
        },
        {
            name: <th>Creation Date</th>,
            selector: row => row?.createDate,
            sortable: true,
            cell: row => (<>
                {getDateFormat(isStringValue(row?.createDate))} {moment(isStringValue(row?.createDate)).format(GET_DISPLAY_TIME_FORMAT)}
            </>)
        }
    ];

    return (
        <>
            {pending && <CustomLoader />}
            <div className={`modal ${transactionModal ? "modal-show" : "fade"}`} id="modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle"> Transaction History</h5>
                            <button type="button" className="small btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className='admin-listing-table2'>
                                <CommonTable
                                    columns={itemColumns}
                                    data={isArrayValue(transactionData)}
                                    noDataComponent={<NoRecord message={`No Transaction History Found`} />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TransactionHistory
