import { vEmail, strongRegularExp } from '../../constants/Constants';
const isTextData = (event) => {
    if (event && event?.length <= 50) {
        return true;
    } else {
        return false;
    };
};

const isNumberData = (event) => {
    if (/^[0-9\b]+$/.test(isIntegerValue(event)) || event === '') {
        return true;
    } else {
        return false;
    };
};

const isEmailData = (event) => {
    if (vEmail?.test(event)) {
        return true;
    } else {
        return false;
    }
};

const isPasswordData = (event) => {
    if (strongRegularExp?.test(event)) {
        return true;
    } else {
        return false;
    }
};

const handleTextValue = (event) => {
    event = event.replace(/[^\w\s]/gi, "")
    event = event.replace("_", "");
    return isStringValue(event);
};

const isStringValue = (event) => {
    if (event) {
        return event?.toString();
    } else {
        return ''
    };
};

const isIntegerValue = (event) => {
    if (event) {
        return parseInt(event);
    } else {
        return 0;
    };
};

const isArrayValue = (value) => {
    if (value && value?.length !== 0) {
        return value;
    } else {
        return [];
    };
};

const isObjectValue = (value) => {
    if (value && Object.keys(value)?.length !== 0) {
        return value;
    } else {
        return {};
    };
};

const isBooleanValue = (value) => {
    if (value && value === true) {
        return true;
    } else {
        return false;
    };
};

export {
    isTextData,
    handleTextValue,
    isStringValue,
    isNumberData,
    isIntegerValue,
    isPasswordData,
    isEmailData,
    isArrayValue,
    isObjectValue,
    isBooleanValue
}