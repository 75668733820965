import moment from 'moment';
import * as DataHandling from '../data-util/DataHandler';

export const GET_DISPLAY_DATE_FORMAT = 'DD-MMM-YYYY';
export const GET_DISPLAY_TIME_FORMAT = 'LT';

export const maxDate = () => {
    return moment(new Date()).format('YYYY-MM-DD');
};

export const getFormatedDate = (date) => {
    return moment(new Date(date)).format('YYYY-MM-DD');
};

export const getDateFormat = (data) => {
    if (DataHandling.isStringValue(data)) {
        return moment(new Date(data)).format(GET_DISPLAY_DATE_FORMAT);
    } else {
        return '';
    }
};

export const getTimeFormat = (data) => {
    if (DataHandling.isStringValue(data)) {
        return moment(new Date(data)).format(GET_DISPLAY_TIME_FORMAT);
    } else {
        return '';
    }
};

export const getCurrentDate = () => {
    return moment(new Date()).format(GET_DISPLAY_DATE_FORMAT);
};

export const getCurrentTime = () => {
    return moment(new Date()).format('LTS');
};

export const addDaysToDate = (date, numOfDays, dateFormat) => {
    if (dateFormat) {
        let newdate = moment(date).add(numOfDays, 'd').toDate();
        return moment(newdate).format(dateFormat);
    } else {
        return moment(date).add(numOfDays, 'd').toDate();
    }
};

export const getQedRulesDateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD');
};