import { getRequestForApi } from "../api-util/CommonRequest";
import { callHttpRequest, methodType } from "../api-util/HttpRequest";

export const CallApiHandler = async (url, variables, apiMethodType) => {
    let data, error;
    let request = getRequestForApi(url, variables || undefined, apiMethodType || methodType.POST);
    await callHttpRequest(request).then((response) => {
        if (response?.status === 201) {
            data = response?.data?.response?.data || response?.data;
            if (response?.data?.error !== 200 || response?.data?.error !== 201) {
                error = response?.data?.response?.data;
            };
        } else {
            error = response?.data?.response?.data || response?.message;
        };
    }).catch((err) => {
        error = err?.response?.data?.message || err;
    });
    console.warn('error', error);
    return { data, error };
};
