import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import * as CustomInput from "../../utility-files/custom-input/CustomInput"
import * as DataHandler from "../../utility-files/data-util/DataHandler"
import { isStringValue } from '../../utility-files/data-util/DataHandler'
import * as Global from "../../constants/Global"
import { decryptData } from "../../utility-files/data-encryption-util/DataEncryption";
import { alertTypes, errorMessages } from "../../utility-files/helper-function/HelperFunction";
import { IMAGE_TYPES } from "../../utility-files/default-values/DefaultValues";
import ShowPasswordIcon from "./ShowPasswordIcon";
import CustomLoader from "../custom-modules/CustomLoader";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";

function InviteAccept() {
    const navigate = useNavigate();
    const alert = useAlert();
    const params = useParams();
    const [inviteData, setInviteData] = useState(null);
    const [pending, setPending] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false)

    const dataCode = decryptData(params?.email)
    useEffect(() => {
        if (params) {
            setInviteData(params)
        }
    }, [params])


    const Invite = async (e) => {
        e.preventDefault();
        if (inviteData?.firstName === "" || inviteData?.phonenumber === "" || inviteData?.password === "" || inviteData?.confirmpassword === "") {
            setSubmitted(true);
            return;
        }
        if (!inviteData?.isFirstNameValid || !inviteData?.isContactNumberValid || !inviteData?.isPasswordValid || !inviteData?.isConfirmPasswordValid || inviteData?.isPasswordValid !== inviteData?.isConfirmPasswordValid) {
            setSubmitted(true);
            return;
        }
        if (inviteData?.isPasswordValid !== inviteData?.isConfirmPasswordValid) {
            setSubmitted(true);
            return;
        }
        setPending(true)
        let variables = {}
        variables.inviteCode = decryptData(params?.inviteCode);
        variables.email = isStringValue(decryptData(params?.email))
        variables.firstName = isStringValue(inviteData?.firstName);
        variables.contactNumber = isStringValue(inviteData?.contactNumber);
        variables.password = isStringValue(inviteData?.password);
        variables.confirmPassword = isStringValue(inviteData?.confirmPassword);
        let request = getRequestForApi(Global.ACCEPT_INVITE, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Invitation Accept  successfully", { type: alertTypes.SUCCESS });
                setInviteData(null);
                setSubmitted(false);
                setPending(false);
                navigate("/login")

            };
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    }

    const disableSubmit = () => {
        if (!inviteData?.email || !inviteData?.firstName || !inviteData?.contactNumber || !inviteData?.password || !inviteData?.confirmPassword) {
            return true;
        };
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <form className="signupPage-Padding" onSubmit={Invite} >
                <div className="form-row">
                    <h1 className="fs-2 fw-bold">Accept invitaion for QED Admin</h1>
                    <p className="fs-6">Please enter your details</p>
                </div>
                <div className="form-row">
                    <label htmlFor="email">Email ID</label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.USER_LOGIN} alt="" />
                        <CustomInput.Email
                            setData={setInviteData}
                            keyName={'email'}
                            className={'form-control'}
                            id="email-name"
                            placeholder={"Enter your email name"}
                            value={DataHandler.isStringValue(decryptData(params?.email))}
                            readOnly={true}
                        />
                    </div>
                </div>

                <div className="form-row" >
                    <label htmlFor="name">First Name<span className="required">*</span></label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.USER_LOGIN} alt />
                        <CustomInput.Text
                            setData={setInviteData}
                            keyName={'firstName'}
                            className={'form-control'}
                            id="first-name"
                            placeholder={"Enter your name"}
                            value={DataHandler.isStringValue(inviteData?.['firstName'])}
                        />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(inviteData?.firstName) === '' ? errorMessages.REQUIRED_FIELD : !inviteData?.isFirstNameValid ? errorMessages.ENTER_NAME : ''}</p>
                    }
                </div>
                <div className="form-row">
                    <label htmlFor="number">Phone Number<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.PHONE_NUMBER} alt />
                        <CustomInput.ContactNumber
                            setData={setInviteData}
                            keyName={'contactNumber'}
                            className={'form-control'}
                            id={"#scontactNumber"}
                            placeholder={"Enter your mobile number"}
                            value={DataHandler.isStringValue(inviteData?.['contactNumber'])}
                        />
                    </div>
                    {submitted && <p className="warninig">{DataHandler.isStringValue(inviteData?.contactNumber) === '' ? errorMessages.REQUIRED_FIELD : !inviteData?.isContactNumberValid ? errorMessages.ENTER_NUMBER : ''}</p>
                    }
                </div>
                <div className="form-row">
                    <label htmlFor="password">Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                        <CustomInput.Password
                            setData={setInviteData}
                            showPassword={showPassword}
                            keyName={"password"}
                            value={DataHandler.isStringValue(inviteData?.['password'])}
                            className="form-control"
                            id="password-name"
                            placeholder="Enter your password here"

                        />
                        <ShowPasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(inviteData?.password) === '' ? errorMessages.REQUIRED_FIELD : ""}</p>}
                    {inviteData?.password && <p className='warninig'>
                        {!inviteData['isPasswordValid'] ? errorMessages.PASSWORD : ''}
                    </p>}
                </div>
                <div className="form-row">
                    <label htmlFor="password">Confirm Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                        <CustomInput.Password
                            showPassword={isShowPassword}
                            setData={setInviteData}
                            keyName={'confirmPassword'}
                            className={'form-control'}
                            id="password-name"
                            placeholder={"Enter your confirm password"}
                            value={DataHandler.isStringValue(inviteData?.['confirmPassword'])}
                        />
                        <ShowPasswordIcon showPassword={isShowPassword} setShowPassword={setIsShowPassword} />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(inviteData?.confirmPassword) === '' ? errorMessages.REQUIRED_FIELD : ""}</p>}
                    {inviteData?.confirmPassword && <p className='warninig'>
                        {!inviteData['isConfirmPasswordValid'] ? errorMessages.PASSWORD :
                            inviteData?.password && inviteData?.confirmPassword && inviteData?.password !== inviteData?.confirmPassword ? 'Password  and confirm password should be same' : ''
                        }
                    </p>}
                </div>

                <div className="form-row">
                    <button type="submit" className={`btn1 ${disableSubmit() ? 'disable-button' : " "}`} onClick={Invite} >Accept</button>
                </div>
                <div className="form-row google-icon">
                    {/* <div className="img_box d-flex Google-signUp">
                        <img src={IMAGE_TYPES.GOOGLE_LOGO} alt />
                        <button type="button" className="btn1 google-signin">Sign in with Google</button>
                    </div> */}
                </div>
                <div className="signup mt-2">
                    <p>By proceeding, you agree to the <a className="text-decoration-none" href="#">Terms and Conditions and Privacy Policy</a></p>
                </div>
            </form>

        </>
    )
}
export default InviteAccept