import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginSignupRoute from './LoginSignupRoute';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/user-module/Login';
import { Signup } from '../pages/user-module/Signup';
import { PATH_SLUG, path } from '../utility-files/helper-function/HelperFunction';
import ErrorPage from '../pages/user-module/ErrorPage';
import ConsumerLite from '../pages/main-module/ConsumerLite';
import BussinessLite from '../pages/main-module/BussinessLite';
import FeedbackList from '../pages/main-module/FeedbackList';
import UserList from '../pages/main-module/UserList';
import InviteAccept from '../pages/common-module/InviteAccept';
import Reports from '../pages/main-module/Reports';
import ViewTokens from '../pages/main-module/ViewTokens';
import WalletName from '../pages/main-module/walletName';

function Routing() {
  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route path={path.LOGIN} element={<LoginSignupRoute><Login /></LoginSignupRoute>} />
        <Route path={path.SIGNUP} element={<LoginSignupRoute><Signup /></LoginSignupRoute>} />
        <Route path={path.HOME} element={<PrivateRoute><BussinessLite /></PrivateRoute>} />
        <Route path={path.ACCEPT_INVITE + PATH_SLUG.ACCEPT_INVITE} element={<LoginSignupRoute><InviteAccept /></LoginSignupRoute>} />
        <Route path={path.CONSUMER_LITE} element={<PrivateRoute><ConsumerLite /></PrivateRoute>} />
        <Route path={path.VIEW_TOKENS + "/:userId"} element={<PrivateRoute><ViewTokens /></PrivateRoute>} />
        <Route path={path.FEEDBACK} element={<PrivateRoute><FeedbackList /></PrivateRoute>} />
        <Route path={path.USERLIST} element={<PrivateRoute><UserList /></PrivateRoute>} />
        <Route path={path.REPORT} element={<PrivateRoute><Reports /></PrivateRoute>} />
        <Route path={path.WALLETNAME} element={<PrivateRoute><WalletName/></PrivateRoute>}/>
        <Route path={path.ERROR} element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Routing