import React, { useEffect, useState } from 'react'
import CommonTable from '../../custom-ui/CommonTable';
import * as Global from '../../constants/Global'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import CustomLoader from '../custom-modules/CustomLoader';
import { isBooleanValue, isStringValue } from '../../utility-files/data-util/DataHandler';
import Invitesend from '../custom-popup-modal/InviteSend';
function UserList() {
    const [userList, setUserList] = useState(null);
    const [userData, setUserData] = useState(null);
    const [pending, setPending] = useState(false);
    const [isShow, setIsShow] = useState(null)

    const InviteShow = () => {
        setIsShow(!isShow)
    };

    useEffect(() => {
        userListDashboard();
    }, []);

    const userListDashboard = async () => {
        setPending(true);
        let request = getRequestForApi(Global.USER_LIST, undefined, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                setUserList(responseData);
                setUserData(responseData);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const listColumns = [
        {
            name: <th>Name</th>,
            selector: row => row?.displayName,
            sortable: true
        },
        {
            name: <th>Email</th>,
            selector: row => row?.email,
            sortable: true
        },

        {
            name: <th>Contact Number</th>,
            selector: row => row?.contactNumber,
            sortable: true
        },
        {
            name: <th>Status</th>,
            selector: row => row?.status,
            sortable: true,
            cell: row => (<>
                <span data-tooltip={isStringValue(row?.status)}>
                    <span className='ellipsis'>
                        {isStringValue(row?.status)?.toLocaleLowerCase() === 'active' ?
                            <span className="logged-in">●</span> :
                            isStringValue(row?.status)?.toLocaleLowerCase() === 'inactive' ?
                                <span className="logged-out">●</span> :
                                <span className="pending-out">●</span>}
                    </span>
                </span>
            </>
            )
        }
    ];

    const handleSearchData = (value) => {
        const searchData = userData.filter(obj => obj?.displayName?.toLocaleLowerCase().includes(value)
            || obj?.email?.toLocaleLowerCase().includes(value) || obj?.contactNumber?.toLocaleLowerCase().includes(value));
        if (searchData && value) {
            setUserList(searchData);
        } else {
            setUserList(userData);
        };
    };
    return (
        <>
            {<CustomLoader pending={pending} />}
            <section className="table-section-listing pt-0"></section>
            <section className="table-section-listing  pt-0">
                <div className="row table-row-padding">
                    {/* <div className="col-lg-4 d-flex justify-content-initial gap-4">
                    </div> */}
                    <div className="col-lg-12">
                        <div className="input-group rounded d-flex justify-content-end gap-4">
                            <div className="input-group mb-3 w-25">
                                <input
                                    type="search"
                                    className="form-control search-bar-border"
                                    placeholder="Search ......"
                                    aria-label="Recipient's username"
                                    onChange={(event) => handleSearchData(event.target.value.toLocaleLowerCase())}
                                    autoComplete="off"
                                />
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary d-flex" onClick={() => InviteShow()}>
                                    <i className="bi bi-plus-circle"></i>&nbsp; &nbsp;Invite User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"50vh"}
                        columns={listColumns}
                        data={userList}
                    />
                </div>
            </section>
            <Invitesend isShow={isShow} onClose={InviteShow} />
        </>
    )
}
export default UserList