import React, { useEffect, useState } from 'react'
import * as Global from '../../constants/Global'
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import CommonTable from '../../custom-ui/CommonTable';
import CustomLoader from '../custom-modules/CustomLoader';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import { alertTypes, exportUserFileName, exportVaultName, jsonToXLSXFile, sourceName } from '../../utility-files/helper-function/HelperFunction';
import FeedbackExport from '../common-module/FeedbackExport';
import { useAlert } from 'react-alert';
import Filter from '../common-module/Filter';
import UpdateFeedBack from '../custom-popup-modal/UpdateFeedback';


function FeedbackList() {
    const alert = useAlert();
    const [feedbackList, setFeedbackList] = useState(null);
    const [feedbackListClone, setFeedbackListClone] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [selectedFeedbacks, setSelectedFeedbacks] = useState(null);
    const [pending, setPending] = useState(false);
    const [feedbackExport, setFeedbackExport] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterDate, setFilterDate] = useState({});
    const [showFeedBack, setShowFeedBack] = useState(false)
    const [statusData, setStatusData] = useState()
    const [readOnlyFeedback, setReadOnlyFeedback] = useState(false)

    useEffect(() => {
        listFeedback();
    }, []);

    const ModalFeedback = (props) => {
        setStatusData(props)
        setShowFeedBack(!showFeedBack);
    };

    const listFeedback = async () => {
        setPending(true);
        let request = getRequestForApi(Global.FEEDBACK_LIST, undefined, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                setFeedbackList(responseData);
                setFeedbackListClone(responseData);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const feedbackColumns = [
        {
            name: <th>Feedback</th>,
            selector: row => row?.feedback,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.feedback)}
            </>),
            width: '20%'
        },
        {
            name: <th>System Details</th>,
            selector: row => row?.systeminfo,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.systeminfo)}
            </>),
            width: '20%'
        },
        {
            name: <th>Created By</th>,
            selector: row => row?.User?.displayName,
            sortable: true,
            cell: row => (<>
                {DataHandler.isStringValue(row?.User?.displayName)}
            </>),
        },
        {
            name: <th>Create Date</th>,
            selector: row => getDateFormat(row?.createdAt),
            sortable: true,
            cell: row => (<>
                {getDateFormat(row?.createdAt)} {getTimeFormat(row?.createdAt)}
            </>),
        },
        {
            name: <th>Entered From</th>,
            selector: row => DataHandler.isStringValue(row?.enteredFrom),
            sortable: true,
            width: '9%',
            cell: row => (<>
                {DataHandler.isStringValue(row?.enteredFrom)}
            </>),
        },
        {
            name: <th>Updated on</th>,
            selector: row => getDateFormat(row?.updateAt),
            sortable: true,
            width: '9%',
            cell: row => (
                <>
                    {getDateFormat(row?.updateAt)} {getTimeFormat(row?.updateAt)}
                </>),
        }, {
            name: <th>Status</th>,
            selector: row => DataHandler.isStringValue(row?.status),
            sortable: true,
            width: '8%',
            cell: row => (<>
                {DataHandler.isStringValue(row?.status)}
            </>),
        },

        {
            name: <th>Action</th>,
            selector: (row) => row?.tokensGenerated,
            sortable: true,
            width: '8%',
            cell: row => (
                <div className="dropdown dropend">
                    <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical" color="rgba(107, 114, 128, 1)" pointerEvents="none"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </a>
                    <ul className="dropdown-menu pointer">
                        {(row.status === "Closed") || (row.status === "Not Required") ? null : <li className='drop-list'>
                            <a href='#' className="dropdown-item" onClick={() => { ModalFeedback(row); setReadOnlyFeedback(false) }}>
                                <img className='drop-list-img' src={IMAGE_TYPES.FEEDBACK_STATUS} alt="" />&nbsp;
                                Update Status
                            </a>
                        </li>}
                        <li className='drop-list'>
                            <a href='#' className="dropdown-item" onClick={() => { ModalFeedback(row); setReadOnlyFeedback(true) }}>
                                <img className='drop-list-img' src={IMAGE_TYPES.FEEDBACK_STATUS} alt="" />&nbsp;
                                View Status
                            </a>
                        </li>
                        {/* <li className={row?.tokensGenerated === 0 ? "disable-button" : ""}><a className="openModel">Clear All Tokens</a></li> */}
                    </ul>
                </div>
            ),
        }
    ];

    const handleSearchData = (value) => {
        const searchData = filterDate?.start ?
            feedbackData.filter(obj => obj?.User?.displayName.toLocaleLowerCase().includes(value) || obj?.enteredFrom?.toLocaleLowerCase().includes(value)) :
            feedbackListClone.filter(obj => obj?.User?.displayName.toLocaleLowerCase().includes(value) || obj?.enteredFrom?.toLocaleLowerCase().includes(value));
        if (searchData && value) {
            setFeedbackList(searchData);
        } else {
            if (filterDate?.start) {
                setFeedbackList(feedbackData);
            } else {
                setFeedbackList(feedbackListClone);
            };
        };
    };

    const onSelectedRowsChange = ({ selectedRows }) => {
        let selectedData = [];
        selectedRows.forEach(element => {
            selectedData.push({
                'Id': element?.id,
                'Feedback': element?.feedback,
                'System Information': element?.systeminfo,
                'Entered From': element?.enteredFrom,
                'Create Date': element?.createdAt,
                'Create By': element?.User?.displayName,
                "Updated On": element?.updateAt,
                "Status": element?.status,
                "Remark": element?.remarks,
            });
        });
        setSelectedFeedbacks(selectedData);
    };

    const callBackApi = (list, sourceName) => {
        setPending(true);
        jsonToXLSXFile(list, exportUserFileName(sourceName), exportVaultName(sourceName));
        alert.show(`Feedback's downloaded successfully`, { type: alertTypes.SUCCESS });
        setSelectedFeedbacks(null);
        setFeedbackExport(!feedbackExport);
        setTimeout(() => {
            setPending(false);
        }, 1000);
    };

    return (
        <>
            {<CustomLoader pending={pending} />}
            <section className="table-section-listing pt-0"></section>
            <section className="table-section-listing  pt-0">
                <div className="row table-row-padding">
                    <div className="col-lg-4 d-flex justify-content-initial gap-4">
                    </div>
                    <div className="col-lg-8">
                        <div className="input-group rounded d-flex justify-content-end gap-4">
                            <div className="input-group mb-3 w-25">
                                <input
                                    type="search"
                                    className="form-control search-bar-border"
                                    placeholder="Search ......"
                                    aria-label="Recipient's username"
                                    onChange={(event) => handleSearchData(event.target.value.toLocaleLowerCase())}
                                    autoComplete="off"
                                />
                            </div>
                            <div>
                                <button type="button" className="btn btn-primary d-flex" onClick={() => setShowFilter(!showFilter)}>
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.FILTER}
                                        alt=""
                                    />
                                    Filter <span className="badge badge-light">{filterDate?.start ? '1' : '0'}</span>
                                </button>
                            </div>
                            <div className={DataHandler.isArrayValue(selectedFeedbacks).length !== 0 ? "" : "disable-button"}>
                                <button type="button" className="btn btn-primary d-flex align-items-center" onClick={() => setFeedbackExport(!feedbackExport)}>
                                    <img
                                        className="icon-images-table"
                                        src={IMAGE_TYPES.FEEDBACK_WHITE}
                                        alt=""
                                    />
                                    Export Feedback's
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"50vh"}
                        columns={feedbackColumns}
                        data={feedbackList}
                        selectableRows
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={pending}
                    />
                </div>
            </section>
            <Filter showFilter={showFilter} setShowFilter={setShowFilter} feedbackList={feedbackListClone} setFeedbackList={setFeedbackList} setFeedbackData={setFeedbackData} filterDate={filterDate} setFilterDate={setFilterDate} listFeedback={listFeedback} />
            <FeedbackExport isShow={feedbackExport} setIsShow={setFeedbackExport} sourceName={sourceName.feedback} callApi={callBackApi} dataList={selectedFeedbacks} />
            <UpdateFeedBack isShow={showFeedBack} setIsShow={setShowFeedBack} onClose={ModalFeedback} propsData={
                statusData} refetch={listFeedback} readOnlyFeedback={readOnlyFeedback} setReadOnlyFeedback={setReadOnlyFeedback} />
        </>
    )
}

export default FeedbackList
