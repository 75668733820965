import React, { useState } from "react"
import { IMAGE, IMAGE_TYPES } from "../../utility-files/default-values/DefaultValues";
import { alertTypes, convertBase64, getBuffer, userDetails } from "../../utility-files/helper-function/HelperFunction";
import * as DataHandler from '../../utility-files/data-util/DataHandler';
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import * as Global from '../../constants/Global'
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { useAlert } from "react-alert";
import CustomLoader from "../custom-modules/CustomLoader";

function ImageUpload({ onSuccess, userData }) {
  const [baseImage, setBaseImage] = useState(null);
  const [pending, setPending] = useState(null);
  const alert = useAlert();

  const handleFileUpload = async (event) => {
    const data = event.target.files[0];
    const buffer = await convertBase64(data);
    var splitString = data.name.split(".");
    var fileExt = splitString[splitString.length - 1];
    if (fileExt.toLowerCase() == "jpeg" || fileExt.toLowerCase() == 'jfif' || fileExt.toLowerCase() == "jpg" || fileExt.toLowerCase() == "gif" || fileExt.toLowerCase() == "png" || fileExt.toLowerCase() == "webp") {
      setBaseImage({ fileUrl: buffer, byteArray: getBuffer(buffer), fileName: data.name });
    } else {
      alert.show('Invalid image type', { type: alertTypes.ERROR });
    };
  };

  const uploadProfileImage = async () => {
    let variables =
      [{
        fileName: baseImage.fileName,
        byteArray: baseImage.byteArray,
        module: "User",
        moduleId: userDetails()?.id,
        ordBy: 0,
        uploadType: 0
      }];
    let request = getRequestForApi(Global.UPDATE_USER_PROFILE, variables, methodType.POST);
    await callHttpRequest(request).then((response) => {
      if (response?.status === 201) {
        let data = response?.data;
        if (data?.status === 200) {
          alert.show(data?.message, { type: alertTypes.SUCCESS });
          onSuccess();
          setBaseImage(null);
        } else {
          alert.show(data?.message, { type: alertTypes.ERROR });
          setBaseImage(null);
        };
      };
      setPending(false);
    }).catch((err) => {
      let error = err?.response?.data?.message;
      alert.show(error, { type: alertTypes.ERROR });
      setPending(false);
    });
  }
  return (
    <>
      {<CustomLoader pending={pending} />}
      <div>
        <div className="modal fade" id="ImageUpload" tabIndex="-1" aria-labelledby="ImageUpload" aria-hidden="false" data-bs-keyboard="false" data-bs-backdrop="static">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Uploading a new photo</h5>
                <button type="button" className="btn-close image_upload_close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                {/* <p className="body-desc">
                  It will be easier for your friends to recognize you if you upload your real photo. You can upload the image in JPG, GIF or PNG format. </p> */}
                <div className="photo-input d-flex justify-content-around">
                  <div>
                    <img className="profile-image-upload" src={DataHandler.isStringValue(baseImage?.fileUrl) || (DataHandler.isStringValue(userData?.profileImageUrl) || IMAGE_TYPES.SKULL)} alt="profile" />
                  </div>
                  <div className='upload-file-div ps-5'>
                    <div className="upload-btn-wrapper">
                      <button className="btn btn-primary">Choose file</button>
                      <input name='fileInput'
                        accept={IMAGE}
                        type="file" className="form-control" id="fileInput"
                        onChange={(event) => handleFileUpload(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-end">
                <button type="button" className="btn btn-secondary image_upload_close" data-bs-dismiss="modal" onClick={() => setBaseImage(null)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={() => uploadProfileImage()}>Save</button>
              </div>
              {/* <div className="modal-footer">
                 <p className="footer-title">
                  If you're having problems uploading, try choosing a smaller photo.</p> 
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ImageUpload;