import React, { useState, useEffect } from "react";
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes, errorMessages, path } from "../../utility-files/helper-function/HelperFunction";
import { Link, useNavigate } from "react-router-dom";
import * as  CustomInput from "../../utility-files/custom-input/CustomInput"
import { IMAGE_TYPES, getDefaultValues } from "../../utility-files/default-values/DefaultValues";
import { CallApiHandler } from "../../utility-files/custom-hooks/CallApiHandler";
import * as Global from '../../constants/Global'
import CustomAlert from '../custom-modules/CustomAlert'
import CustomLoader from "../custom-modules/CustomLoader";
import { useAlert } from "react-alert";
import ShowPasswordIcon from "../common-module/ShowPasswordIcon";


export function Signup() {
    const alert = useAlert()
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate();
    const [pending, setPending] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);

    useEffect(() => {
        setSubmitted(false);
    }, [userData]);

    const signUp = async (e) => {
        e.preventDefault();
        if (userData?.firstName === "" || userData?.email === "" || userData?.phonenumber === "" || userData?.password === "" || userData?.confirmpassword === "") {
            setSubmitted(true);
            return;
        }
        if (!userData?.isFirstNameValid || !userData?.isEmailValid || !userData?.isContactNumberValid || !userData?.isPasswordValid || !userData?.isConfirmPasswordValid || userData?.isPasswordValid !== userData?.isConfirmPasswordValid) {
            setSubmitted(true);
            return;
        }
        if (userData?.isPasswordValid !== userData?.isConfirmPasswordValid) {
            setSubmitted(true);
            return;
        }
        setPending(true);
        const variables = getDefaultValues().SIGNUP_VARIABLES;
        variables.firstName = userData?.['firstName'];
        variables.email = userData?.['email'];
        variables.contactNumber = userData?.['contactNumber'];
        variables.password = userData?.['password'];
        variables.confirmPassword = userData?.['confirmPassword'];

        const response = await CallApiHandler(Global.USER_SIGNUP, variables);
        const data3 = response?.data;
        const error = response?.error;
        if (data3) {
            let data = getDefaultValues().ALERT_OPTIONS;
            data.passphrase = data3?.wallet?.passphrase[0];
            data.showButton = true;
            alert.show(<CustomAlert data={data} />, { type: alertTypes.SUCCESS });
            navigate("/login");
        };
        if (error) {
            alert.show(error, { types: alertTypes.ERROR })
        };
        setPending(false);
    };
    return (
        <>
            {<CustomLoader pending={pending} />}
            <form className="signupPage-Padding" onSubmit={signUp} >
                <div className="form-row">
                    <h1 className="fs-2 fw-bold">Sign up for QED Admin</h1>
                    <p className="fs-6">Please enter your details</p>
                </div>
                <div className="form-row" >
                    <label htmlFor="name">First Name<span className="required">*</span></label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.USER_LOGIN} alt />
                        <CustomInput.Text
                            setData={setUserData}
                            keyName={'firstName'}
                            className={'form-control'}
                            id="first-name"
                            placeholder={"Enter your name"}
                            value={DataHandler.isStringValue(userData?.['firstName'])}
                        />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(userData?.firstName) === '' ? errorMessages.REQUIRED_FIELD : !userData?.isFirstNameValid ? errorMessages.ENTER_NAME : ''}</p>}
                </div>
                {/* <div className="form-row">
                    <label htmlFor="name">Last Name<span className="required">*</span></label>
                    <div className="input-box d-flex briefcase">
                        <img src={IMAGE_TYPES.BRIEFCASE_PASS} alt />
                        <CustomInput.Text
                            setData={setUserData}
                            keyName={'lastName'}
                            className={'form-control'}
                            id="last-name"
                            placeholder={"Enter your last Name"}
                            value={DataHandler.isStringValue(userData?.['lastName'])}
                        />
                    </div>
                    {submitted && <p className="warninig">{DataHandler.isStringValue(userData?.lastName) === '' ? errorMessages.REQUIRED_FIELD : !userData?.isLastNameValid ? errorMessages.ENTER_COMPANYNAME : ''}</p>}
                </div> */}
                <div className="form-row">
                    <label htmlFor="email">Email ID<span className="required">*</span></label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.USER_LOGIN} alt="" />
                        <CustomInput.Email
                            setData={setUserData}
                            keyName={'email'}
                            className={'form-control'}
                            id="email-name"
                            placeholder={"Enter your email name"}
                            value={DataHandler.isStringValue(userData?.['email'])}
                        />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(userData?.email) === '' ? errorMessages.REQUIRED_FIELD : !userData?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p>}
                </div>

                <div className="form-row">
                    <label htmlFor="number">Phone Number<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.PHONE_NUMBER} alt />
                        <CustomInput.ContactNumber
                            setData={setUserData}
                            keyName={'contactNumber'}
                            className={'form-control'}
                            id={"#scontactNumber"}
                            placeholder={"Enter your mobile number"}
                            value={DataHandler.isStringValue(userData?.['contactNumber'])}
                        />
                    </div>
                    {submitted && <p className="warninig">{DataHandler.isStringValue(userData?.contactNumber) === '' ? errorMessages.REQUIRED_FIELD : !userData?.isContactNumberValid ? errorMessages.ENTER_NUMBER : ''}</p>}
                </div>
                <div className="form-row">
                    <label htmlFor="password">Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                        <CustomInput.Password
                            setData={setUserData}
                            showPassword={showPassword}
                            keyName={"password"}
                            value={DataHandler.isStringValue(userData?.['password'])}
                            className="form-control"
                            id="password-name"
                            placeholder="Enter your password here"

                        />
                        <ShowPasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(userData?.password) === '' ? errorMessages.REQUIRED_FIELD : ""}</p>}
                    {userData?.password && <p className='warninig'>
                        {!userData['isPasswordValid'] ? errorMessages.PASSWORD : ''}
                    </p>}
                </div>
                <div className="form-row">
                    <label htmlFor="password">Confirm Password<span className="required">*</span></label>
                    <div className="input-box d-flex Phone">
                        <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                        <CustomInput.Password
                            showPassword={isShowPassword}
                            setData={setUserData}
                            keyName={'confirmPassword'}
                            className={'form-control'}
                            id="password-name"
                            placeholder={"Enter your confirm password"}
                            value={DataHandler.isStringValue(userData?.['confirmPassword'])}
                        />
                        <ShowPasswordIcon showPassword={isShowPassword} setShowPassword={setIsShowPassword} />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(userData?.confirmPassword) === '' ? errorMessages.REQUIRED_FIELD : ""}</p>}
                    {userData?.confirmPassword && <p className='warninig'>
                        {!userData['isConfirmPasswordValid'] ? errorMessages.PASSWORD :
                            userData?.password && userData?.confirmPassword && userData?.password !== userData?.confirmPassword ? 'Password  and confirm password should be same' : ''
                        }
                    </p>}
                </div>

                <div className="form-row">
                    <button type="submit" className='btn1' onClick={signUp} >Sign up</button>
                </div>
                <div className="form-row google-icon">
                    {/* <div className="img_box d-flex Google-signUp">
                        <img src={IMAGE_TYPES.GOOGLE_LOGO} alt />
                        <button type="button" className="btn1 google-signin">Sign in with Google</button>
                    </div> */}
                </div>
                <div className="signup mt-2">
                    <p>By proceeding, you agree to the <a className="text-decoration-none" href="#">Terms and Conditions and Privacy Policy</a></p>
                </div>
                <div className="go-login mt-2">
                    <Link to={path.LOGIN} className="text-decoration-none go-to-login">Go to login</Link>
                </div>
            </form>
        </>
    )
}
