import React, { useEffect, useState } from 'react'
import CommonTable from '../../custom-ui/CommonTable';
import * as Global from '../../constants/Global'
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import CustomLoader from '../custom-modules/CustomLoader';
import AddName from '../custom-popup-modal/AddName';
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';
import UpdateWalletName from '../custom-popup-modal/UpdateWallateName';
import Confirmation from '../custom-popup-modal/DeleteWalltename';
import AssignEmail from '../custom-popup-modal/AssignEmail';


function WalletName() {

    const [pending, setPending] = useState(false);
    const [isShow, setIsShow] = useState(false)
    const [walletList, setWalletList] = useState(null)
    const [updateList, setUpdateList] = useState(null)
    const [walletPopUp, setWalletPopUp] = useState(false)
    const [walletData, setWalletData] = useState(null);
    const [userName, setUserName] = useState(null);
    const [EmailPopup, setEmailPopup] = useState(null);
    const [userIds, setUserIds] = useState(null);
    const [itemId, setItemId] = useState(null)
    const [walletNameDelete, setWalletNameDelete] = useState(null)

    const AddWalletName = () => {
        setIsShow(!isShow)
    };

    const tokenShow = () => {
        setUserName(!userName);
    };
    const emailShow = () => {
        setEmailPopup(!EmailPopup);
    };

    const exportTokensPopup = (props) => {
        setWalletPopUp(!walletPopUp);
    };
    const deleteName = () => {
        setWalletNameDelete(!walletNameDelete)
    }

    useEffect(() => {
        walletListDashboard();
    }, []);

    const walletListDashboard = async () => {
        setPending(true);
        let variables = {
            pageNo: 0,
            perPageItem: 0,
            name: ""
        }

        let request = getRequestForApi(Global.WALLET_NAME_LIST, variables, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                const idArray = responseData.map(item => item.id);
                setItemId(idArray)
                setWalletList(responseData);
                setWalletData(responseData);
                setUserIds(null);
            };
            setPending(false);
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };
    const listColumns = [
        {
            name: <th>Name</th>,
            selector: row => row?.name,
            sortable: true
        },
        {
            name: <th>Email</th>,
            selector: row => row?.usedForEmail,
            sortable: true
        },
        {
            name: <th>Action</th>,
            selector: row => row?.usedForEmail,
            width: '10%',
            cell: row => (<div className="dropdown dropend">
                <a href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical" color="rgba(107, 114, 128, 1)" pointerEvents="none"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </a>
                <ul className="dropdown-menu pointer">
                    <li className={`drop-list ${row?.usedForEmail ? "disable-button" : ""}`} onClick={() => {
                        setUpdateList(row)
                        setUserName(row?.id)
                    }}>
                        <a href='#' className="dropdown-item">
                            <img className='drop-list-img' src={IMAGE_TYPES.EDIT_ICON} alt="" />&nbsp;
                            Edit
                        </a>
                    </li>
                    <li className={`drop-list ${row?.usedForEmail ? "disable-button" : ""}`} onClick={() => { selectedRowId(row); setWalletNameDelete(row?.id) }}>
                        <a href='#' className="dropdown-item">
                            <img className='drop-list-img' src={IMAGE_TYPES.DELETE_ICON} alt="" />&nbsp;
                            Delete
                        </a>
                    </li>
                    <li className={`drop-list ${row?.usedForEmail ? "disable-button" : ""}`} onClick={() => { setEmailPopup(row?.id) }} >
                        <a href='#' className="dropdown-item">
                            <img className='drop-list-img' src={IMAGE_TYPES.EMAIl_ICON} alt="" />&nbsp;
                            Assign Email
                        </a>
                    </li>
                </ul>
            </div>
            ),
        }


    ];

    const selectedRowId = ({ id }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [id];
        setUserIds(selectedUsers);
        exportTokensPopup();
    };

    const onSelectedRowsChange = ({ selectedRows }) => {
        let selectedUsers = {};
        selectedUsers.userIds = [];
        for (let index = 0; index < selectedRows?.length; index++) {
            let userId = selectedRows?.[index]["id"]
            selectedUsers.userIds[index] = userId;
        };
        setUserIds(selectedUsers);
    };


    return (
        <>
            {<CustomLoader pending={pending} />}
            <section className="table-section-listing pt-0"></section>
            <section className="table-section-listing  pt-0">
                <div className="row table-row-padding">
                    {/* <div className="col-lg-4 d-flex justify-content-initial gap-4">
            </div> */}
                    <div className="col-lg-12">
                        <div className="input-group rounded d-flex justify-content-end gap-4">
                            <div>
                                <button type="button" className="btn btn-primary d-flex" onClick={() => AddWalletName()}>
                                    <i className="bi bi-plus-circle"></i>&nbsp; &nbsp;Add&nbsp; &nbsp;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='admin-listing-table'>
                    <CommonTable
                        fixedHeader
                        fixedHeaderScrollHeight={"50vh"}
                        columns={listColumns}
                        data={walletList}
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={pending}
                    />
                </div>
            </section>
            <AddName isShow={isShow} onClose={AddWalletName} onSuccess={walletListDashboard} />
            <UpdateWalletName userId={userName} closeUpdateWallet={tokenShow} walletNameId={userName} onSuccess={walletListDashboard} dataPopUP={updateList} />
            <Confirmation wallet={walletNameDelete} closeWallet={deleteName} walletDeleteId={walletNameDelete} onSuccess={walletListDashboard} />
            <AssignEmail EmailWallet={EmailPopup} closeEmail={emailShow} walletId={EmailPopup} onSuccess={walletListDashboard} />
        </>
    )
}
export default WalletName;
