import React, { useEffect, useState } from "react";
import * as Global from "../../constants/Global"
import { alertMessages, alertTypes } from "../../utility-files/helper-function/HelperFunction";
import { callHttpRequest, methodType } from "../../utility-files/api-util/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-util/CommonRequest";
import { useAlert } from "react-alert";
import * as CustomInput from "../../utility-files/custom-input/CustomInput"
import CustomLoader from "../custom-modules/CustomLoader";
import * as DataHandler from '../../utility-files/data-util/DataHandler';


function UpdateFeedBack(props) {
    const { isShow, setShow, onClose, propsData, refetch,readOnlyFeedback} = props
    const [feedbackStatus, setFeedbackStatus] = useState()
    const [updateStatus, setUpdateStatus] = useState()
    const [selectedStatus, setSelectedStatus] = useState()
    const [pending, setPending] = useState(false)
    const alert = useAlert()

    useEffect(() => {
    getStatus()
    setSelectedStatus(propsData?.status)
    setFeedbackStatus((propsData?.status === "Closed") ||( propsData?.status==="Not Required")  || readOnlyFeedback ? propsData  :"")
    }, [isShow])

    const feedbackdata = async () => {
        setPending(true)
        let variabels = {}
        variabels.id = propsData?.id;
        variabels.remarks = feedbackStatus?.remarks;
        variabels.status = selectedStatus
        ;
     
        let request = getRequestForApi(Global.UPDATE_FEEDBACK, variabels, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                alert.show("Feedback status updated successfully.", { type: alertTypes.SUCCESS });
                setFeedbackStatus();
                refetch()
                onClose()
            };
            setPending(false)
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false)
        })
    }
    const getStatus = async () => {
        setPending(true);
        let request = getRequestForApi(Global.GET_STATUS, undefined, methodType.POST)
        await callHttpRequest(request).then((response) => {
            if (response?.status === 201) {
                let responseData = response?.data?.response?.data;
                setUpdateStatus(responseData);
                setPending(false);
            }
        }).catch((err) => {
            console.log("err", err);
            setPending(false);
        })
    };

    const statusHandler = (data) => {
        setSelectedStatus(data);
    }
      

    const disableSubmit=()=>{
        if (!feedbackStatus?.remarks ) {
            return true;
        };
     }     
    

    return (
        <>
     
            {<CustomLoader pending={pending} />}
            <div className={(`modal ${isShow ? "modal-show" : "fade"}`)} id={isShow} tabIndex="-1" role="dialog" aria-labelledby={isShow} aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header hide-border">
                            <div className="modal-head">
                                <h5 className="modal-title" id="exampleModalLabel">{(propsData?.status === "Closed") || ( propsData?.status==="Not Required")   || readOnlyFeedback ? "View Status" : "Update Status"}</h5>
                            </div>
                        </div>

                        <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Status:</label>
                            <select className={(propsData?.status === "Closed") ||  ( propsData?.status==="Not Required")  || readOnlyFeedback ? "pointer-evt-none" : ""}  value={selectedStatus} id="status-selectbox" onChange={(e) => statusHandler(e.target.value)}>
                                {updateStatus?.map((data) => {
                                    return <>
                                        <option value={data.name}>{data.name}</option>
                                    </>
                                })}
                            </select>  
                        </div>
                        <div className="form-group">
                                <label htmlFor="recipient-name" className="col-form-label">Remarks:</label>
                            <CustomInput.TextArea
                                setData={setFeedbackStatus}
                                value={DataHandler.isStringValue(feedbackStatus?.["remarks"])}
                                keyName={"remarks"}
                                className="form-control default-font"
                                placeholder=""
                                id="remarks"
                                isrequired={true}
                                readOnly={(propsData?.status === "Closed") || ( propsData?.status==="Not Required") || readOnlyFeedback ? true : false }
                            />
                        </div>
                        <div className="modal-footer hide-border mt-3">
                            {(propsData?.status === "Closed") || ( propsData?.status==="Not Required")  || readOnlyFeedback ? null : <>
                            <button type="button" className={`btn  btn-primary btn-sm ${disableSubmit() ? 'disable-button' : " "}`} data-bs-dismiss="modal" onClick={() => feedbackdata()}>Save</button>
                            </>}
                            <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss={"modal"} onClick={() => { onClose(); setUpdateStatus() }} >Cancel</button>
                           
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default UpdateFeedBack;