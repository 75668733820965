export const IMAGE = "image/x-png,image/gif,image/jpeg";
export const ATTACHMENTS = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
export const CSV = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

export const IMAGE_TYPES = {
    QEDLOGO: require('../../assets/images/qed-logo.png'),
    USER_LOGIN: require("../../assets/images/User.webp"),
    LOCK_PASS: require('../../assets/images/Lock.webp'),
    BRIEFCASE_PASS: require('../../assets/images/briefcase.png'),
    LOAD_GIF: require("../../assets/images/load.gif"),
    PHONE_NUMBER: require('../../assets/images/mobile.png'),
    LOGO_IMAGE: require('../../assets/images/logo.svg').default,
    NOTIFICATION_IMAGE: require('../../assets/images/notification.png'),
    PROFILE: require('../../assets/images/profile.png'),
    TOKEN: require('../../assets/images/free-token.png'),
    ICON_IMAGE: require('../../assets/images/green-icon.png'),
    TOKEN2_IMAGE: require('../../assets/images/purchased-token.png'),
    TOTALSALE_IMAGE: require('../../assets/images/total-sale.png'),
    RED_IMAGE: require('../../assets/images/red-icon.png'),
    SKULL: require('../../assets/images/skeleton-img.svg').default,
    GREENICON_IMAGE: require('../../assets/images/green-icon.png'),
    TOTALUSER_IMAGE: require('../../assets/images/total-user.png'),
    BUSSINESSLITE_LOGO: require('../../assets/images/business-lite-logo.png'),
    PEOPLEIMAGE: require('../../assets/images/people.png'),
    TICKET_IMAGE: require('../../assets/images/ticket.png'),
    SETTING_LOGO: require('../../assets/images/setting.png'),
    SETTING_NEW_LOGO: require('../../assets/images/setting-icon.png'),
    LOGOUT_LOGO: require('../../assets/images/logout.png'),
    PEOPLENEW_LOGO: require('../../assets/images/icon-images-table.png'),
    FREE_TOKEN: require('../../assets/images/token.png'),
    DOWNLOAD_TOKEN: require('../../assets/images/download.png'),
    CLEAR_ALL_TOKEN: require('../../assets/images/clear.png'),
    SEARCH_ICON: require('../../assets/images/search-icon.png'),
    FEEDBACK: require('../../assets/images/feedback.png'),
    UESRS: require('../../assets/images/users.png'),
    FILTER: require('../../assets/images/filter.png'),
    COIN: require('../../assets/images/coin.png'),
    FEEDBACK_WHITE: require('../../assets/images/feedback2.png'),
    FEEDBACK_STATUS: require("../../assets/images/icons8-status.gif"),
    REPORT: require("../../assets/images/report-icon.png"),
    SEARCH_WHITE: require("../../assets/images/search.png"),
    REFRESH_ICON: require("../../assets/images/refresh.png"),
    ARROW_RIGHT: require("../../assets/images/arrow-square-right.svg").default,
    SELECTED_ARROW: require('../../assets/images/arrow-square-right-selected.svg').default,
    ARROW: require('../../assets/images/arrow-fwd.png'),
    PDFIMAGE: require('../../assets/images/pdfimg.png'),
    CARIMG: require('../../assets/images/carimage.jpg'),
    CLOSEICON: require('../../assets/images/close.png'),
    DELETEICON: require('../../assets/images/deleteicon.png'),
    TOKENTITLE: require('../../assets/images/tokentitle.png'),
    PDF_ICON: require('../../assets/images/pdf-img.svg').default,
    XLXS: require('../../assets/images/xls.svg').default,
    CLOSE_CIRCLE_IMAGE: require('../../assets/images/close-circle.svg').default,
    BAN_ICON: require("../../assets/images/ban.png"),
    DELETE_ICON: require("../../assets/images/delete.png"),
    EMAIl_ICON: require("../../assets/images/email.png"),
    EDIT_ICON: require("../../assets/images/edit.png"),
    UAT: require('../../assets/images/UAT.svg').default,
    TOKEN_ICON2: require('../../assets/images/tokens-icon.svg').default,
    ACTIVITY: require('../../assets/images/activity.svg').default
};

export const getDefaultValues = () => {
    let variables = {}
    variables.LOGIN_VARIABLES = {
        email: '',
        password: ''
    };
    variables.ALERT_OPTIONS = {
        showButton: false
    };

    variables.SIGNUP_VARIABLES = {
        email: "",
        firstName: "",
        contactNumber: '',
        lastName: '',
        password: "",
        confirmPassword: "",
    };
    variables.CATEGORY_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        name: "",
        Columns: [""]
    };
    variables.ITEM_LIST_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        name: "",
        searchText: "",
        category: "",
        skuCode: "",
        upcCode: "",
        identifierCode: "",
        flag: 0,
        createDate: ""
    };
    variables.ADD_ITEM_LIST_VARAIABLES = {
        name: '',
        description: "",
        category: "",
        skuCode: "",
        upcCode: "",
        identifierCode: "",
        dataLinks: "",
    };
    variables.UPLOAD_TYPE_VARIABLES = [
        {
            moduleId: "",
            module: "User",
            fileName: "",
            ordBy: 0,
            uploadType: 0,
            byteArray: ""
        }
    ];
    variables.GENERATE_TOKEN_VARIABLES = {
        itemId: 0,
        upcCode: "",
        serialNo: "",
        otherIdentifierCode: "",
        description: "",
        notes: "",
        expiryDate: ""
    }
    variables.TOKENS_LIST_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        serialNumber: "",
        flag: 0,
        timeFlag: 0,
        createDate: "",
        searchText: "",
        index: "",
        owner: ""
    }
    variables.TOKEN_DETAILS_VARIABLES = {
        pageNo: 0,
        perPageItem: 0,
        index: ""
    }
    variables.TOKEN_TRANSFER = {
        email: "",
        index: [""]
    }

    return variables;
};
export const PLACE_HOLDER_TEXT = {
    email: "Enter your email here",
    password: "Enter your password  here",
    newPassword: 'Enter your new password',
    confirmPassword: 'Please confirm your password',
    wallet_Name: "Enter your wallet name here",
    item_name: "Enter item name",
    serial_no: "Enter serial number",
    identifier: 'Enter a value',
    description: 'Enter description here',
    skuCode: 'Enter sku code here',
    upcCode: 'Enter upc code here',
    otp: 'Enter OTP here',
    CURRENT_PASSWORD: "Current Password",
    notes: 'Enter notes here',
};
export const DEFAULT_HOLDER_VAlUES = {
    loading: 'Please wait...'
}
export const TOUR_STEPS = [
    {
        target: ".qed-logo",
        content: "This is our tour logo.",
        disableBeacon: true,
    },
    {
        target: ".my-sm-0",
        content: "click here to go to signup page",
    },
    {
        target: ".login",
        content: "Login from here 😉.",
    },
    {
        target: ".google-signin",
        content: "Sign in with Google 😉.",
    },
];
