import React, { useEffect, useState } from 'react'
import { alertMessages, alertTypes, errorMessages, path } from '../../utility-files/helper-function/HelperFunction';
import { Link, useNavigate } from 'react-router-dom';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import * as CustomInput from '../../utility-files/custom-input/CustomInput'
import * as Global from '../../constants/Global'
import { DEFAULT_HOLDER_VAlUES, IMAGE_TYPES, PLACE_HOLDER_TEXT, getDefaultValues } from '../../utility-files/default-values/DefaultValues';
import { setStorageData, storageKey, storageType } from '../../utility-files/storege-util/StorageHandling';
import { useAlert } from 'react-alert';
import ShowPasswordIcon from '../common-module/ShowPasswordIcon';
import CustomLoader from '../custom-modules/CustomLoader';
import ForgotPassword from '../custom-popup-modal/ForgotPassword';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import { encryptData } from '../../utility-files/data-encryption-util/DataEncryption';


function Login() {
    const alert = useAlert();
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [rememberme, setRememberme] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setSubmitted(false)
    }, [loginData])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (loginData?.email == '' || loginData?.password == '') {
            setSubmitted(true);
            return;
        }
        if (!loginData?.isEmailValid || !loginData?.isPasswordValid) {
            setSubmitted(true);
            return
        }
        setPending(true);
        const variables = getDefaultValues().LOGIN_VARIABLES;
        variables.email = encryptData(loginData?.['email']);
        variables.password = encryptData(loginData?.['password']);
        let request = getRequestForApi(Global.USER_LOGIN, variables, methodType.POST);
        await callHttpRequest(request).then(async response => {
            if (response?.status === 201) {
                let token = response?.data?.token;
                if (token) {
                    if (rememberme) {
                        setStorageData(storageType, storageKey.TOKEN, token);
                    } else {
                        setStorageData(!storageType, storageKey.TOKEN, token);
                    }
                    navigate('/');
                    alert.show(alertMessages.LOGGEDIN, { type: alertTypes.SUCCESS });
                };
            };
            setPending(false);
        }).catch((err) => {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
            console.log('err', err)
        });
    };

    return (
        <>
            <CustomLoader pending={pending} />
            <form onSubmit={onSubmit}>
                <div className="form-row">
                    <h1>Welcome Back</h1>
                    <p>Please enter your details</p>
                </div>
                <div className="form-row">
                    <label htmlFor="email">Email ID</label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.USER_LOGIN} alt="" />
                        <CustomInput.Email
                            setData={setLoginData}
                            keyName={"email"}
                            value={DataHandler.isStringValue(loginData?.['email'])}
                            className="form-control"
                            id="email-name"
                            placeholder={PLACE_HOLDER_TEXT.email}
                        />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(loginData?.email) === '' ? errorMessages.REQUIRED_FIELD : !loginData?.isEmailValid ? errorMessages.ENTER_VALID_EMAIL : ''}</p>}
                </div>
                <div className="form-row">
                    <label htmlFor="password">Password</label>
                    <div className="input-box d-flex">
                        <img src={IMAGE_TYPES.LOCK_PASS} alt="" />
                        <CustomInput.Password
                            showPassword={showPassword}
                            setData={setLoginData}
                            keyName={"password"}
                            value={DataHandler.isStringValue(loginData?.['password'])}
                            className="form-control"
                            id="password-name"
                            placeholder={PLACE_HOLDER_TEXT.password}
                        />

                        <ShowPasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                    </div>
                    {submitted && <p className='warninig'>{DataHandler.isStringValue(loginData?.password) === '' ? errorMessages.REQUIRED_FIELD : !loginData?.isPasswordValid ? errorMessages.PASSWORD : ''}</p>}
                </div>
                <div className="form-row forgot-pass">
                    <div className="password">
                        <a onClick={() => setShowModal("#ForgotPassWord")} href='#'>Forgot password</a>
                    </div>
                    {/* <div className="rememberme">
                        <input type="checkbox" checked={rememberme} onChange={() => setRememberme(!rememberme)} />
                        <span>Remember me</span>
                    </div> */}
                </div>
                <div className="form-row">
                    <button type="submit" className='btn1' onClick={onSubmit} >{pending ? DEFAULT_HOLDER_VAlUES.loading : `Log in`}</button>
                </div>
                <div className="form-row google-icon">
                    {/* <div className="img_box d-flex Google">
                        <img src={IMAGE_TYPES.GOOGLE_LOGO} alt='Google' />
                        <button type="button" className="btn1 google-signin">Sign in with Google</button>
                    </div> */}
                </div>

                {/* <div className="signup">
                    <p>Don't have an account? <Link className="text-decoration-none" to={path.SIGNUP}>Sign up</Link></p>
                </div> */}
            </form>
            {showModal && DataHandler.isStringValue(showModal) === "#ForgotPassWord" && <ForgotPassword showModal={showModal} setShowModal={setShowModal} />}
        </>
    )
}

export default Login