import React, { useEffect, useState } from 'react'
import { callHttpRequest, methodType } from '../../utility-files/api-util/HttpRequest';
import * as Global from '../../constants/Global';
import { getRequestForApi } from '../../utility-files/api-util/CommonRequest';
import * as DataHandler from '../../utility-files/data-util/DataHandler'
import { alertTypes } from '../../utility-files/helper-function/HelperFunction';
import { useAlert } from 'react-alert';
import CommonTable from '../../custom-ui/CommonTable';
import { getDateFormat, getTimeFormat } from '../../utility-files/date-util/DateHandling';
import { IMAGE_TYPES } from '../../utility-files/default-values/DefaultValues';

function ViewActivity({ userId, onClose }) {
    const alert = useAlert();
    const [pending, setPending] = useState(false);
    const [activities, setActivities] = useState(null);

    useEffect(() => {
        if (userId) {
            usersToken();
        };
    }, [userId]);

    const usersToken = async () => {
        setPending(true);
        let variables = {
            userId: DataHandler.isStringValue(userId),
        };
        try {
            let request = getRequestForApi(Global.READ_USER_ACTIVITIES, variables, methodType.POST);
            await callHttpRequest(request).then((response) => {
                if (response?.status === 201) {
                    console.log(response?.data?.response?.data)
                    setActivities(response?.data?.response?.data);
                };
            });
        } catch (err) {
            alert.show(err?.response?.data?.message, { type: alertTypes.ERROR });
            setPending(false);
        } finally {
            setPending(false);
        };
    };

    const activitiesColumns = [
        {
            name: 'Activity Name',
            selector: (row) => row?.activityName,
            sortable: true
        },
        {
            name: 'Date',
            selector: (row) => row?.createdAt,
            sortable: true,
            cell: row => (<>{getDateFormat(row?.createdAt)} {getTimeFormat(row?.createdAt)} </>)
        }
    ];

    return (
        <div className={`modal ${userId ? "modal-show" : "fade"}`} id={userId} tabIndex="-1" role="dialog" aria-labelledby={userId} aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel"><img src={IMAGE_TYPES.ACTIVITY} alt="" /> &nbsp;View Activities</h1>
                        <button type="button" class="btn-close" onClick={() => onClose()} aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <CommonTable
                            fixedHeader
                            fixedHeaderScrollHeight={"50vh"}
                            data={activities}
                            columns={activitiesColumns}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewActivity