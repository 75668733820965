import React from 'react'

function CommonLoginModule({ children }) {
    return (
        <>
            <div className="container-fluid bg-light container-background">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        {children}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className='slider-signup img-fluid'>
                            {/* <img className="login-banner-img w-100" src={IMAGE_TYPES.RIGHT_BANNER} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommonLoginModule